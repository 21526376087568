import React from 'react'
import { Button, Card, Col, Image, Row } from 'react-bootstrap'
import Dropdown from 'react-bootstrap/Dropdown';

import TicketIcon from '../../../assets/icons/sidebar/ticket.svg'
import DropDownIcon from '../../../assets/icons/common/down-arrow-solid.svg'
import EditIcon from '../../../assets/icons/common/edit.svg'
import DeleteIcon from '../../../assets/icons/common/delete.svg'
import MoreIcon from '../../../assets/icons/common/more.svg'

import CardColumn from './CardColumn'
import SelectStatusElement from '../../../components/extras/SelectStatusElement'
import ActionElements from '../../../components/extras/ActionElements';

function CardElement({  icon=undefined, data, page }) {


  const InvoiceElements = ({ total, printId }) => {

    const PrintBtn = ({ id }) => {
      return (
        <Button variant='success' className='text-white btn-sm'>
          Print Invoice
        </Button>
      )
    }

    return (
      <>
        <CardColumn size_mid={2} column_name='Total' element={total} />
        <CardColumn size_mid={2} column_name='Print' element={<PrintBtn id={printId} />} />
      </>
    );
  }

  const editClick = (id) => {
    console.log(id);
  }

  const deleteClick = (id) => {
    console.log(id);
  }

  const CustomDropdownItems = () => {
    return (
      <Dropdown.Item href={data.url}>Details</Dropdown.Item>
    )
  }

  return (
    <Card className='w-100 shadow-basic border-1 mb-2'>
      <Card.Body className='d-flex align-items-center'>
        <Image src={icon ?? TicketIcon} />
        <span style={{ marginLeft: '10px', fontSize: '14px' }}>Dummy-Ticket-ID</span>
      </Card.Body>

      <Card.Body className=''>
        <Row>
          <CardColumn size_mid={page == 'ticket' ? 3 : 2} column_name='Ticket Id' element='Dummy-Ticket-ID' />
          <CardColumn size_mid={2} column_name='Issue' element='Hardware' />
          <CardColumn size_mid={2} column_name='Status' element={<SelectStatusElement data={{ 'status': data.status, 'page': page }} />} />
          <CardColumn size_mid={2} column_name='Date' element='DD/MM/YYYY' />
          {page == 'ticket' ?
            <CardColumn size_mid={3} column_name='Actions' element={<ActionElements id={1} editClick={editClick} deleteClick={deleteClick} dropdownItems={<CustomDropdownItems />} />} />
            :
            <InvoiceElements total={'$ 520'} printId={1} />
          }

        </Row>
      </Card.Body>
    </Card>
  )
}

export default CardElement