import React from 'react'
import {
  Button,
  Col,
  Container,
  Form,
  Image,
  InputGroup,
} from 'react-bootstrap'
import moment from 'moment'
import { CLASSES } from '../../../assets/styles/classes'
import MoreIcon from '../../../assets/icons/common/more.svg'
import EditIcon from '../../../assets/icons/common/edit.svg'
import DeleteIcon from '../../../assets/icons/common/delete.svg'
import PlusIcon from '../../../assets/icons/common/plus-white.svg'
import UserIcon from '../../../assets/icons/sidebar/users-1.svg'
import TicketIcon from '../../../assets/icons/sidebar/ticket.svg'
import DropDownIcon from '../../../assets/icons/common/down-arrow-solid.svg'

import Table from '../../../components/table/Table'
import { fakeData } from '../../../utils/faker'
import DropDownX from '../../../components/extras/DropDownX'
import SearchFilter from '../../../components/extras/SearchFilter'
import { useNavigate } from 'react-router-dom'
import ActionElements from '../../../components/extras/ActionElements'

export default function Admin() {
  const navigate = useNavigate()
  const columns = [
    {
      name: 'Admin Id',
      width: '150px',
      style: {
        fontWeight: 'bold',
      },
      cell: ({ userId }) => userId.substr(0, 8),
    },
    {
      name: 'Admin Name',
      minWidth: '180px',
      cell: ({ username }) => username,
    },
    {
      name: 'Phone Number',
      minWidth: '180px',
      cell: ({}) => `01${Math.round(Math.random() * 1000000000)}`,
    },
    {
      name: 'Email',
      minWidth: '180px',
      cell: ({ email }) => email,
    },
    {
      name: 'Date',
      // minWidth: '180px',
      cell: ({ birthdate }) => moment(birthdate).format('DD MMM, YYYY'),
    },
    {
      name: 'Actions',
      width: '120px',
      style: { paddingRight: '30px' },
      center: true,
      allowOverflow: true,
      cell: (row) => (
        <ActionElements
          id={row.userId || 1}
          editClick={() => navigate('/admin/edit/1')}
          deleteClick={() => {}}
          viewClick={() => navigate('/admin/details/1')}
        />
      ),
    },
  ]

  return (
    <Container fluid className='p-4 py-2 mt-2'>
      <div>
        <div className={CLASSES.content_between}>
          <h4 className={CLASSES.items_center}>
            {' '}
            <Image src={TicketIcon} className='me-3' /> All Admin list
          </h4>
          <Button
            onClick={() => navigate('/admin/create')}
            className={CLASSES.items_center + 'bg-blue'}
          >
            <Image src={PlusIcon} className={'me-2'} /> Add New Admin
          </Button>
        </div>

        <div className='mb-5 mt-3 pt-4 border-radius-10 black-shadow-15'>
          <div className={CLASSES.content_between + 'px-4 mb-4'}>
            <div className='px-0 px-md-2'>
              <SearchFilter />
            </div>

            <div className={CLASSES.content_between + 'px-0 px-md-2'}>
              <DropDownX label='Sort by : ' />
            </div>
          </div>

          <Table
            columns={columns}
            data={fakeData(10)}
            customStyle={{
              rows: {
                style: {
                  borderTop: '0px solid #E0E0E0',
                  borderBottom: '1px solid #E0E0E0 !important',
                  display: 'flex !important',
                  alignItems: 'center !important',
                  padding: 15,
                },
              },
            }}
          />
        </div>
      </div>
    </Container>
  )
}
