import React from 'react'
import { Button, Card, Col, Image, Row } from 'react-bootstrap'
import Dropdown from 'react-bootstrap/Dropdown'
import ReactToPrint, { useReactToPrint } from 'react-to-print';
//
import TicketIcon from '../../../assets/icons/sidebar/ticket.svg'
import CardColumn from '../../customer/components/CardColumn'
import SelectStatusElement from '../../../components/extras/SelectStatusElement'
import { PrintInvoice, useScreenSizes } from '../../../utils/helperFunctions'
import { CLASSES } from '../../../assets/styles/classes'
import ModalX from '../../../components/others/ModalX'
import TicketDetails from '../../ticket/pages/TicketDetails'

function CardElement({ icon = undefined, data, page }) {
  const [isOpen, setIsOpen] = React.useState(false)

  const CardColumn = ({ column_name, element }) => {
    return (
      <div className='d-flex align-items-center flex-column mb-3'>
        <b style={{ fontSize: '14px' }}>{column_name}</b>
        <p
          className={
            useScreenSizes().isDesktopOrLaptop ? 'mt-3 mb-0' : 'mt-1 mb-0'
          }
          style={{ fontSize: '13px' }}
        >
          {element}
        </p>
      </div>
    )
  }

  const PrintCardColumn = () => {
    const componentRef = React.useRef();
    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
    });
    const PrintBtn = ({ handlePrint }) => {
      return (
        <>
          <Button onClick={handlePrint} variant='success' className='text-white btn-sm'>
            Print Invoice
          </Button>
        </>
      )
    }
    return (
      <>
      <CardColumn column_name='Print' element={<PrintBtn handlePrint={handlePrint} />} />
        <div style={{ display: "none" }}>
          <TicketDetails type='modal' ref={componentRef} />
        </div>
      </>
    )
  }


  const editClick = (id) => {
    console.log(id)
  }

  const deleteClick = (id) => {
    console.log(id)
  }

  return (
    <>

      <Card className='w-100 shadow-basic border-1 mb-2'>
        <Card.Body className='d-flex align-items-center'>
          <Image src={icon ?? TicketIcon} />
          <span style={{ marginLeft: '10px', fontSize: '14px' }}>
            Dummy-Ticket-ID
          </span>
        </Card.Body>

        <Card.Body className=''>
          <div className={useScreenSizes().isTabletOrMobile ? 'd-block' : CLASSES.content_evenly + ' flex-wrap '} >
            <CardColumn column_name='Customer Id' element='Dummy-customer-ID' />
            <CardColumn column_name='Customer Name' element={data.customer_name} />
            <CardColumn column_name='Ticket Id' element='Dummy-Ticket-ID' />
            <CardColumn column_name='Issue' element='Hardware' />
            <CardColumn column_name='Status' element={<SelectStatusElement data={{ status: data.status, page: page }} />} />
            <CardColumn column_name='Date' element='DD/MM/YYYY' />
            <CardColumn column_name='Total' element={'1200'} />
            <PrintCardColumn />
          </div>
        </Card.Body>
      </Card>
      {/* <ModalX isOpen={isOpen} toggle={() => setIsOpen(false)} size='xl' title='Invoice Print'>
        <TicketDetails type='modal' />
      </ModalX> */}
    </>
  )
}

export default CardElement
