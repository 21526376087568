import Nav from 'react-bootstrap/Nav'
import Image from 'react-bootstrap/Image'
import { Link } from 'react-router-dom'
import BellIcon from '../../assets/icons/common/bell.svg'

function ButtonsSection() {
  return (
    <div className='d-flex align-items-center'>
      <Nav.Link href='#'>
        <Link className='navbar-link px-2' to={'/hotel'}>
          <Image src={BellIcon} />
        </Link>
      </Nav.Link>
    </div>
  )
}

export default ButtonsSection
