import CustomerAssets from '../pages/customer-assets/pages/CustomerAssets'
import CreateCustomerAsset from '../pages/customer-assets/pages/CreateCustomerAsset';


const CustomerAssetsRoutes = [
  {
    path: '/assets',
    component: CustomerAssets,
  },
  {
    path: '/assets/create',
    component: CreateCustomerAsset,
  },
  {
    path: '/assets/edit/:id',
    component: CreateCustomerAsset,
  },
];

export default CustomerAssetsRoutes
