export const CLASSES = {
  items_center: 'd-flex align-items-center ',
  content_center: 'd-flex justify-content-center align-items-center ',
  content_end: 'd-flex justify-content-end align-items-center ',
  content_between: 'd-flex justify-content-between align-items-center ',
  content_evenly: 'd-flex justify-content-evenly align-items-center ',
}

export const COLORS = {
  orange: '#F3922F',
  red: '#EF3333',
  blue: '#288BCF',
  blue_10: "#F9FBFF",
  blue_50: "#eaf5fc",
  gray: '#717171',
  black: '#000000',
  black50: '#747474',
  badge: '#FBDDBF'
};
