import React from 'react'
import { FormGroup, Col, Image, FormLabel, InputGroup } from 'react-bootstrap'

import { Controller } from 'react-hook-form'
import 'flatpickr/dist/flatpickr.css'
import Flatpickr from 'react-flatpickr'
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect'
import 'flatpickr/dist/plugins/monthSelect/style.css'
import TimeIcon from '../../../assets/icons/common/time.svg'
//
import LabelErrors from '../LabelErrors'

const CustomInput = ({ value, defaultValue, inputRef, ...props }) => {
  return <input {...props} defaultValue={defaultValue} ref={inputRef} />
}

const TimePicker = ({
  label = '',
  name = '',
  defaultValue = undefined,
  placeholder = '',
  errorMessage = '',
  noGrid = false,
  control = {},
  dateFormat = 'Y-m-d',
  onlyMonth = false,
  minDate = '',
  maxDate = '',
  enableTime = true,
  colClass = '',
  formGroupClass = '',
  xxl = '4',
  xl = '4',
  lg = '4',
  md = '6',
  sm = '12',
  xs = '12',
}) => {
  const plugins = [
    new monthSelectPlugin({
      shorthand: true,
      dateFormat,
      altFormat: 'F Y',
      theme: 'light',
    }),
  ]
  const inputRef = React.useRef(null)

  const renderInput = () => (
    <>
      {label && <FormLabel for={name}>{label}</FormLabel>}
      <InputGroup>
        <InputGroup.Text className=''>
          <Image src={TimeIcon} />
        </InputGroup.Text>

        <Controller
          name={name}
          defaultValue={defaultValue}
          render={({ field }) => (
            <>
              <Flatpickr
                {...field}
                className='form-control'
                placeholder={placeholder || 'Select date'}
                options={{
                  disableMobile: true,
                  // position: 'below center',
                  enableTime: true,
                  noCalendar: true,
                  minuteIncrement: 1,
                }}
                ref={inputRef}
                // render={
                //   ({defaultValue, value, ...props}, ref) => {
                //     return <CustomInput defaultValue={defaultValue} inputRef={ref} />
                //   }
                // }
              />
              {field?.value ? (
                <div className='position-relative'>
                  <button
                    type='button'
                    onClick={() => {
                      inputRef.current.props.onChange('')
                      // if (!inputRef?.current?.flatpickr) return;
                      // inputRef.current.flatpickr.clear();
                    }}
                    style={{ left: -35, top: 0 }}
                    className='btn close position-absolute'
                    aria-label='Close'
                  >
                    <span aria-hidden='true'>&times;</span>
                  </button>
                </div>
              ) : null}
            </>
          )}
          control={control}
        />
      </InputGroup>
      <LabelErrors name={name} errorMessage={errorMessage} />
    </>
  )

  return (
    <>
      {noGrid ? (
        renderInput()
      ) : (
        <>
          <Col
            className={colClass}
            xs={xs}
            sm={sm}
            md={md}
            lg={lg}
            xl={xl}
            xxl={xxl}
          >
            <FormGroup className={formGroupClass}>{renderInput()}</FormGroup>
          </Col>
        </>
      )}
    </>
  )
}

export default TimePicker
