import { FormLabel } from 'react-bootstrap'

const LabelErrors = ({
  name = '',
  labelClass ='',
  errorMessage = '',
}) => (
  <FormLabel
    className={`text-danger ${labelClass}`}
    for={name}
  >
    {errorMessage.toLowerCase()}
  </FormLabel>
)
export default LabelErrors;