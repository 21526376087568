import React from 'react'
import CardElement from './CardElement'

function CustomerTicketTab({data}) {
    // loop through data
    // print card elements
    return (
        <div className='w-100'>
          <CardElement data={{ 'status': 1, 'url': 'dummy ticket details url' }} page='ticket' />
          <CardElement data={{ 'status': 0 }} page='ticket' />
        </div>
      )
}

export default CustomerTicketTab