import React, { Component } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from '../pages/home/Home';
import Header from '../components/header';
import PageNotFound from '../pages/no-page/PageNotFound';
import Sidebar from '../components/sidebar/Sidebar';
import CustomerRoutes from './CustomerRoutes';
import TicketsRoutes from './TicketsRoutes';
import AdminRoutes from './AdminRoutes';
import CustomerAssetsRoutes from './CustomerAssetsRoutes';
import ScrollToTop from '../components/extras/ScrollToTop';
import InvoiceRoutes from './InvoiceRoutes';
import AppointmentRoutes from './AppointmentRoutes';

// const Login = lazy(() => import('../pages/login/LoginPage'));


class RoutersList extends Component {
  render() {
    return (
      <div className='app-body'> {/* overflow-hidden */}
        {/* <Suspense fallback={<div className="loader"></div>}>
                </Suspense> */}
        <BrowserRouter basename="/">
          <div className='d-flex'>
            <Sidebar />
            <div className='d-flex flex-column w-100 vh-100 overflow-auto'>
              <Header />
              <ScrollToTop />
              <Routes >
                <Route path="/" Component={Home} />
                {CustomerRoutes.map((item) => <Route key={item.path} path={item.path} Component={item.component} />)}
                {TicketsRoutes.map((item) => <Route key={item.path} path={item.path} Component={item.component} />)}
                {InvoiceRoutes.map((item) => <Route key={item.path} path={item.path} Component={item.component} />)}
                {AdminRoutes.map((item) => <Route key={item.path} path={item.path} Component={item.component} />)}
                {CustomerAssetsRoutes.map((item) => <Route key={item.path} path={item.path} Component={item.component} />)}
                {AppointmentRoutes.map((item) => <Route key={item.path} path={item.path} Component={item.component} />)}
                <Route path="/*" Component={PageNotFound} />
              </Routes>

            </div>
          </div>
        </BrowserRouter>

      </div>
    );
  }
}

export default RoutersList; 