import React from 'react'
import { CLASSES } from '../../assets/styles/classes'
import { Image } from 'react-bootstrap'

function HeaderInfo({ Img, title, element }) {
  return (
    <div className={CLASSES.content_between}>
      <h4 className={CLASSES.items_center}>
        {' '}
        <Image src={Img} className='me-3' /> {title}
      </h4>
      <div className={CLASSES.items_center + 'text-bold'}>
        {element}
      </div>
    </div>
  )
}

export default HeaderInfo