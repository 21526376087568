import Customer from '../pages/customer/pages/Customer'
import CreateCustomer from '../pages/customer/pages/CreateCustomer';
import CustomerDetails from '../pages/customer/pages/CustomerDetails';


const CustomerRoutes = [
  {
    path: '/customer',
    component: Customer,
  },
  {
    path: '/customer/create',
    component: CreateCustomer,
  },
  {
    path: '/customer/edit/:id',
    component: CreateCustomer,
  },
  {
    path: '/customer/details',
    component: CustomerDetails,
  }
];

export default CustomerRoutes
