import DataTable from 'react-data-table-component'
import './style.scss'
import CustomStylesDataTable from './CustomStyleDataTable'
import CustomPagination from './CustomPagination'

const Table = ({
  columns,
  data,
  className,
  style,
  customStyle,
  responsive = true,
  pagination = true,
  conditionalRowStyles = () => {}
}) => {
  const handlePagination = (page) => {
    console.log(page)
  }
  return (
    <>
      <div style={{ ...style }} className={className}>
        {/* <TableColumnFilter {...{
          columnSearch,
          setColumnSearch,
          columns,
        }}
        /> */}
        <DataTable
          columns={columns}
          className='react-dataTable-custom pb-3'
          data={data}
          highlightOnHover
          // paginationPerPage={5}
          responsive={responsive}
          pagination={pagination}
          paginationComponent={() => <CustomPagination handlePagination={handlePagination} />}
          customStyles={{
            ...CustomStylesDataTable,
            rows: {
              style: {
                borderTop: '0px solid #E0E0E0',
                borderBottom: '0px !important',
                display: 'flex !important',
                alignItems: 'center !important',
                padding: 15,
              },
            },
            ...customStyle,
          }}
          conditionalRowStyles={conditionalRowStyles}
        />
      </div>
    </>
  )
}

export default Table
