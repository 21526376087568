import React from 'react'
import { InputGroup, FormControl, Button, Image, Form } from 'react-bootstrap';
import SearchIcon from '../../../assets/icons/common/search.svg'


function SearchInput() {
  const [isKeyDown, setKeyDown] = React.useState(false);
  return (
    <InputGroup
      onFocus={() => setKeyDown((x) => !x)}
      onBlur={() => setKeyDown((x) => !x)}
      className='px-0 border-gray bg-light overflow-hidden mt-2'
      style={{ border: "none", borderBottom: "1px solid #BEBEBE" }}
    >
      {!isKeyDown && (
        <button
          style={{ backgroundColor: '#F9FBFF', top: 0, bottom: 0 }}
          className='search-button bg-muted px-3 position-absolute'
        >
          <Image src={SearchIcon} />
        </button>
      )}
      <Form.Control
        placeholder='Search'
        aria-label='Search'
        aria-describedby='basic-addon2'
        className={`border-0 search-input bg-none ${isKeyDown ? 'px-3' : 'ps-5'}`}
      />
    </InputGroup>
  )
}

export default SearchInput