import React from 'react'
import { InputGroup, Image, Form } from 'react-bootstrap'
import SearchIcon from '../../assets/icons/common/search.svg'
import { COLORS } from '../../assets/styles/classes';

export default function SearchFilter() {
  const [isKeyDown, setKeyDown] = React.useState(false);
  return (
    <InputGroup
      onFocus={() => setKeyDown((x) => !x)}
      onBlur={() => setKeyDown((x) => !x)}
      className='px-0 border-gray bg-muted border-radius-10 overflow-hidden p-1'
    >
      {!isKeyDown && (
        <button
          style={{ backgroundColor: COLORS.blue_10, top: 0, bottom: 0 }}
          className='search-button bg-muted px-3 position-absolute'
        >
          <Image src={SearchIcon} />
        </button>
      )}
      <Form.Control
        placeholder='Search'
        aria-label='Search'
        aria-describedby='basic-addon2'
        className={`border-0 search-input bg-none ${isKeyDown ? 'px-3' : 'ps-5'}`}
      />
    </InputGroup>
  )
}
