import Nav from 'react-bootstrap/Nav'
import Image from 'react-bootstrap/Image'
import { Link } from 'react-router-dom'

import DownArrowIcon from '../../assets/icons/common/down-arrow.svg'

function UserSection({setShowMenu= () => {}}) {
  return (
    <div className='d-flex align-items-center justify-content-end'>
      <Nav.Link href='#' onClick={setShowMenu} className='ms-3 px-2'>
        <div className='d-flex justify-content-center align-items-center' style={{height: 30, aspectRatio: 1/1, overflow: 'hidden', borderRadius: '100%'}}>
          <Image style={{height: 30}} src={'https://img.freepik.com/free-photo/handsome-bearded-guy-posing-against-white-wall_273609-20597.jpg?size=626&ext=jpg&ga=GA1.1.1700460183.1708387200&semt=sph'} />
        </div>
      </Nav.Link>
      <div className='d-flex align-items-center ms-2'>
        Hanny <Image src={DownArrowIcon} className='ms-2' />
      </div>
    </div>
  )
}

export default UserSection
