import React from 'react'
import {
  Button,
  Col,
  Container,
  Form,
  Image,
  Dropdown,
  DropdownButton,
} from 'react-bootstrap'
import moment from 'moment'
import { CLASSES } from '../../../assets/styles/classes'
import MoreIcon from '../../../assets/icons/common/more.svg'
import EditIcon from '../../../assets/icons/common/edit.svg'
import DeleteIcon from '../../../assets/icons/common/delete.svg'
import PlusIcon from '../../../assets/icons/common/plus-white.svg'
import UserIcon from '../../../assets/icons/sidebar/users-1.svg'
import TicketIcon from '../../../assets/icons/sidebar/ticket.svg'
import DropDownIcon from '../../../assets/icons/common/down-arrow-solid.svg'

import Table from '../../../components/table/Table'
import { fakeData } from '../../../utils/faker'
import DropDownX from '../../../components/extras/DropDownX'
import SearchFilter from '../../../components/extras/SearchFilter'
import { useNavigate } from 'react-router-dom'
import HorizontalNavbar from '../components/HorizontalNavbar'
import HeaderInfo from '../../../components/extras/HeaderInfo'
import ModalX from '../../../components/others/ModalX'
import TicketDetails from './TicketDetails'
import ActionElements from '../../../components/extras/ActionElements'

export default function Tickets() {
  const navigate = useNavigate()
  const [isOpen, setIsOpen] = React.useState(false)
  const columns = [
    {
      name: 'Ticket Id',
      width: '150px',
      style: {
        fontWeight: 'bold',
      },
      cell: ({ userId }) => userId.substr(0, 8),
    },
    {
      name: 'Customer Name',
      minWidth: '180px',
      cell: ({ username }) => username,
    },
    {
      name: 'Phone Number',
      minWidth: '180px',
      cell: ({}) => `01${Math.round(Math.random() * 1000000000)}`,
    },
    {
      name: 'Email',
      minWidth: '180px',
      cell: ({ email }) => email,
    },
    {
      name: 'Issue',
      minWidth: '180px',
      cell: ({}) => 'Software',
    },
    {
      name: 'Status',
      minWidth: '180px',
      center: true,
      cell: ({}) => (
        <div
          style={{ borderRadius: 20, border: '1px solid #F3922F' }}
          className={CLASSES.items_center + 'p-1 px-2 text-orange'}
        >
          On Going <Image src={DropDownIcon} className={'ms-2'} />
        </div>
      ),
    },
    {
      name: 'Date',
      // minWidth: '180px',
      cell: ({ birthdate }) => moment(birthdate).format('DD MMM, YYYY'),
    },
    {
      name: 'Actions',
      width: '120px',
      style: { paddingRight: '30px' },
      center: true,
      allowOverflow: true,
      cell: (row) => (
        <ActionElements
          id={1}
          editClick={(id) => navigate(`/ticket/edit/${id}`)}
          deleteClick={() => {}}
          viewClick={() => {
            setIsOpen(true)
            // navigate(`/ticket/details/1`, { state: { id: itemId } })
          }}
        />
      ),
    },
  ]

  const DropDownDetails = ({ itemId }) => {
    return (
      <Dropdown>
        <Dropdown.Toggle
          as={DropdownButton}
          variant='white'
          title={<Image src={MoreIcon} className='pointer' />}
        ></Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item
            onClick={() => {
              setIsOpen(true)
              // navigate(`/ticket/details/1`, { state: { id: itemId } })
            }}
            eventKey={itemId}
          >
            Details
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    )
  }

  return (
    <Container fluid className='p-4 py-2 mt-2'>
      <div>
        <HeaderInfo
          Img={TicketIcon}
          title='All Ticket'
          element={'Total Ticket :560'}
        />
        {/* <div className={CLASSES.content_between}>
           <h4 className={CLASSES.items_center}>
             {' '}
             <Image src={TicketIcon} className='me-3' /> All Ticket
           </h4>
           <div className={CLASSES.items_center + 'text-bold'}>
             Total Ticket: 500
           </div>
         </div> */}

        <div className={CLASSES.content_between + 'my-2'}>
          <HorizontalNavbar />
          <Button
            onClick={() => navigate('/ticket/create')}
            className={CLASSES.items_center + 'bg-blue'}
          >
            <Image src={PlusIcon} className={'me-2'} /> Add New Ticket
          </Button>
        </div>

        <div className='mb-5 mt-3 pt-4 border-radius-10 black-shadow-15'>
          <div className={CLASSES.content_between + 'px-4 mb-4'}>
            <div className='px-0 px-md-2'>
              <SearchFilter />
            </div>

            <div className={CLASSES.content_between + 'px-0 px-md-2'}>
              <DropDownX label='Issue : ' />
              <DropDownX label='Sort by : ' />
            </div>
          </div>

          <Table
            columns={columns}
            data={fakeData(10)}
            customStyle={{
              rows: {
                style: {
                  borderTop: '0px solid #E0E0E0',
                  borderBottom: '1px solid #E0E0E0 !important',
                  display: 'flex !important',
                  alignItems: 'center !important',
                  padding: 15,
                },
              },
            }}
          />
        </div>
      </div>
      <ModalX isOpen={isOpen} toggle={() => setIsOpen(false)} size='xl'>
        <TicketDetails type='modal' />
      </ModalX>
    </Container>
  )
}
