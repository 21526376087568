import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import Image from 'react-bootstrap/Image'
import Container from 'react-bootstrap/Container'
import { Link } from 'react-router-dom'
import Search from './Search'
import UserSection from './UserSection'
import ButtonsSection from './ButtonsSection'
// import Menu from '../../assets/icons/header/menu.svg'
import CalenderIcon from '../../assets/icons/common/calender.svg'
import UserMenu from './UserMenu'

function Header() {
  const [showMenu, setShowMenu] = React.useState(false)

  React.useEffect(() => {
    if (showMenu) {
      var dropdown = document.querySelector('.user-menu-btn')
      const handleShowMenu = (event) => {
        console.log('clicked outside user menu...')
        var isClickInside = dropdown?.contains(event.target)
        if (!isClickInside) {
          setShowMenu(false)
        }
      }
      document.addEventListener('click', handleShowMenu)
      return () => {
        document.addEventListener('click', handleShowMenu)
      }
    }
  }, [showMenu])

  return (
    <>
      <Navbar
        sticky='top'
        collapseOnSelect
        expand='lg'
        className='border-bottom-gray bg-white'
        style={{zIndex: 99}}
      >
        <Container fluid className='py-2 position-relative'>
          <Row className='w-100 m-0'>
            <Col className='d-flex justify-content-between align-items-center'>
              <div>
                <h5>Welcome Back !</h5>
                <div className='d-flex align-items-center'>
                  <Image src={CalenderIcon} className='me-2 svg-icon' />
                  <span className='text-gray'>23 Dec, 2023</span>
                </div>
              </div>
              <Search />
              <div className='d-flex align-items-center'>
                <ButtonsSection />
                <UserSection
                  setShowMenu={() => {
                    // setShowMenu((x) => !x)
                  }}
                />
              </div>
            </Col>
          </Row>
          {showMenu && <UserMenu setShowMenu={setShowMenu} />}
        </Container>
      </Navbar>
    </>
  )
}

export default Header
