import { configureStore } from '@reduxjs/toolkit';
//import {persistReducer, persistStore} from 'redux-persist';
//
import rootReducer from './reducers'

// const persistConfig = {
//   key: 'root',
//   storage: AsyncStorage,
//   whitelist: [] //['auth', 'tab', 'common'],
// };


// const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: rootReducer,
})