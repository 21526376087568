import React from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { COLORS } from '../../../assets/styles/classes';
import Form from 'react-bootstrap/Form';
import { useForm } from 'react-hook-form'
import ReactstrapInput from '../../../components/form/input-fields/ReactstrapInput';
import { Row } from 'react-bootstrap';

function VerticalModal(props) {

    const {
        formState: { errors, isValid },
        watch,
        reset,
        control,
        setValue,
        handleSubmit,
    } = useForm({
        mode: 'all',
        defaultValues: {
            title: '',
            comment: '',
        },
    })

    const onSubmit = async (values) => {
        console.log(values);
        return false
    }

    return (
        <Modal
            {...props}
            style={{ fontSize: '14px' }}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton> </Modal.Header>
            <Modal.Body>
                <Row>
                    <ReactstrapInput
                        {...{
                            label: 'Title',
                            name: 'title',
                            type: 'text',
                            placeholder: 'Enter title here',
                            errorMessage: errors?.title?.message,
                            control,
                            lg: '12',
                            xl: '12',
                            xxl: '12',
                        }}
                    />

                    <ReactstrapInput
                        {...{
                            label: 'Comment',
                            name: 'comment',
                            as: 'textarea',
                            rows: 5,
                            type: 'text',
                            placeholder: 'Enter comments',
                            errorMessage: errors?.comment?.message,
                            control,
                            lg: '12',
                            xl: '12',
                            xxl: '12',
                        }}
                    />
                </Row>

                <Form>

                    {/* <Form.Group className="mb-3" controlId="title">
                        <Form.Label>Title</Form.Label>
                        <Form.Control style={{ fontSize: '14px' }} type="email" placeholder="Enter title here" />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="comment">
                        <Form.Label>Comment</Form.Label>
                        <Form.Control style={{ fontSize: '14px' }} as="textarea" rows={5} placeholder='Enter your comment' />
                    </Form.Group> */}

                </Form>
            </Modal.Body>
            <Modal.Footer className='d-flex justify-content-between align-items-center'>
                <Form.Select style={{ fontSize: '14px' }} className='w-50'>
                    <option>Message</option>
                    <option>Email</option>
                </Form.Select>
                <Button onClick={handleSubmit(onSubmit)} style={{ backgroundColor: COLORS.blue, color: 'white', fontSize: '14px' }} className='px-4'>Send</Button>
            </Modal.Footer>
        </Modal>
    );
}

function CommunicateModal() {
    const [modalShow, setModalShow] = React.useState(false);

    return (
        <>

            <div className='mb-3 w-100 text-end'>

                <Button variant={COLORS.blue} style={{ backgroundColor: COLORS.blue, color: 'white' }} onClick={() => setModalShow(true)}>
                    +  Add New
                </Button>

            </div>

            <VerticalModal
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </>
    )
}

export default CommunicateModal