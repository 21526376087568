import React, {useCallback } from 'react'
import { Button, Col, Container, Image, Row } from 'react-bootstrap'
import HeaderInfo from '../../../components/extras/HeaderInfo'
import FileIcon from '../../../assets/icons/sidebar/file.svg'
import PlusIcon from '../../../assets/icons/common/plus-white.svg'
import { CLASSES } from '../../../assets/styles/classes'
import { useNavigate } from 'react-router-dom'
import SearchFilter from '../../../components/extras/SearchFilter'
import DropDownX from '../../../components/extras/DropDownX'
import { useMediaQuery } from 'react-responsive'
import CardElement from '../components/CardElement'
import { useScreenSizes } from '../../../utils/helperFunctions'

function Invoice() {
    const navigate = useNavigate()

    const exampleData = [
        {
            id: 1,
            status: 0,
            customer_name: "Jakie Chan",
            date: "2022-01-01",
            amount: "5300",
            type: "Hardware"
        },
        {
            id: 2,
            status: 1,
            customer_name: "Elon Musk",
            date: "2022-02-01",
            amount: "1200",
            type: "Repair"
        },
        {
            id: 3,
            status: 0,
            customer_name: "Chinese Elon Ma",
            date: "2022-03-04",
            amount: "200",
            type: "Software"
        }
    ];

    const InvoiceCard = () => {
        return (
            <>
                {exampleData.map((data) => (
                    <CardElement
                        key={data.id}
                        icon={FileIcon}
                        data={data}
                        page="invoice"
                    />
                ))}
            </>
        );
    };

    return (
        <Container fluid className='p-4 py-2 mt-2'>
            <div>
                <HeaderInfo Img={FileIcon} title="Invoices" element={"Total Invoice : 7"} />
            </div>
            {/* // TODO: This may be added later - in development */}
            {/* 
            <div className={CLASSES.content_end + 'my-2'}>
          <Button
            onClick={() => navigate('/ticket/create')}
            className={CLASSES.items_center + 'bg-blue'}
          >
            <Image src={PlusIcon} className={'me-2'} /> Add New Ticket
          </Button>
        </div> 
        */}

            <div className="mb-5 mt-3 pt-4">
                <Row className='mb-4'>
                    <Col>
                        <SearchFilter />
                    </Col>
                    <Col lg={6} ></Col>
                    <Col className={useScreenSizes().isTabletOrMobile ? 'mt-2' : ''}>
                        <div className={(useScreenSizes().isTabletOrMobile ? CLASSES.content_center : CLASSES.content_between) + 'px-0'}>
                            <DropDownX label='Issue : ' />
                            <DropDownX label='Sort by : ' />
                        </div>
                    </Col>
                </Row>

                <InvoiceCard />

            </div>

        </Container>
    )
}

export default Invoice