import React, { Fragment } from 'react'
import { Button, Col, Container, Image, Row, Dropdown } from 'react-bootstrap'
import moment from 'moment'
import { CLASSES } from '../../assets/styles/classes'
import MoreIcon from '../../assets/icons/common/more.svg'
import EditIcon from '../../assets/icons/common/edit.svg'
import DeleteIcon from '../../assets/icons/common/delete.svg'
import DropDownIcon from '../../assets/icons/common/down-arrow-solid.svg'
// import BarChart from '../../components/common/BarChart'
import PlusIcon from '../../assets/icons/common/plus-white.svg'
import UserIcon from '../../assets/icons/dashboard/user.svg'
import TicketIcon from '../../assets/icons/dashboard/ticket.svg'
import SettingIcon from '../../assets/icons/dashboard/setting.svg'
import FileIcon from '../../assets/icons/dashboard/file.svg'
import OverviewCard from './components/OverviewCard'
import BarGraphCard from './components/BarGraphCard'
import Table from '../../components/table/Table'
import { fakeData } from '../../utils/faker'
import { Link, useNavigate } from 'react-router-dom'
import ActionElements from '../../components/extras/ActionElements'

const items = [
  { title: 'New Customer', link: '/customer/create' },
  { title: 'New Ticket', link: '/ticket/create' },
  { title: 'New Invoice', link: '/invoice/create' },
]
export default function Home() {
  // const [selectedItem, setSelectedItem] = React.useState(items[0])
  const navigate = useNavigate()
  const columns = [
    {
      name: 'Ticket Id',
      width: '120px',
      style: {
        fontWeight: 'bold',
      },
      cell: ({ userId }) => userId.substr(0, 8),
    },
    {
      name: 'Customer Name',
      minWidth: '180px',
      cell: ({ username }) => username,
    },
    {
      name: 'Phone Number',
      minWidth: '180px',
      cell: ({}) => `01${Math.round(Math.random() * 1000000000)}`,
    },
    {
      name: 'Email',
      minWidth: '180px',
      cell: ({ email }) => email,
    },
    {
      name: 'Issue',
      minWidth: '180px',
      cell: ({}) => 'Software',
    },
    {
      name: 'Status',
      minWidth: '180px',
      center: true,
      cell: ({}) => (
        <div
          style={{ borderRadius: 20, border: '1px solid #F3922F' }}
          className={CLASSES.items_center + 'p-1 px-2 text-orange'}
        >
          On Going <Image src={DropDownIcon} className={'ms-2'} />
        </div>
      ),
    },
    {
      name: 'Date',
      // minWidth: '180px',
      cell: ({ birthdate }) => moment(birthdate).format('DD MMM, YYYY'),
    },
    {
      name: 'Actions',
      width: '120px',
      style: { paddingRight: '30px' },
      center: true,
      allowOverflow: true,
      cell: (row) => (
        <ActionElements
          id={1}
          editClick={() => navigate('/ticket/edit/1')}
          deleteClick={() => {}}
          viewClick={(id) => {
            //  setIsOpen(true)
            navigate(`/ticket/details/1`, { state: { id } })
          }}
        />
      ),
    },
  ]

  return (
    <Container fluid className='p-4 py-2'>
      <div className='mt-2'>
        <div className={CLASSES.content_between}>
          <h4>Overview</h4>
          <Dropdown data-bs-theme='light' className=''>
            <Dropdown.Toggle
              id='dropdown-button-dark-example1'
              className={CLASSES.content_between}
              variant=''
            >
              <Button className={CLASSES.items_center + 'bg-blue'}>
                <Image src={PlusIcon} className={'me-2'} /> Add New
              </Button>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {items.map((item, i) => (
                <Fragment key={i}>
                  {i !== 0 && <Dropdown.Divider />}
                  <Dropdown.Item
                    onClick={() => navigate(item.link)}
                    active={i == 100}
                  >
                    {item.title}
                  </Dropdown.Item>
                </Fragment>
              ))}
            </Dropdown.Menu>
          </Dropdown>
          {/* <Button className={CLASSES.items_center+'bg-blue'}>
            <Image src={PlusIcon} className={'me-2'}/> Add New
          </Button> */}
        </div>
        <Row className='mt-3'>
          {[
            {
              title: 'Total Customer',
              icon: UserIcon,
              style: {
                border: '1px solid #9ED0F2',
                backgroundColor: '#56ADE9',
              },
              value: '500',
            },
            {
              title: 'Total Ticket',
              icon: TicketIcon,
              style: {
                border: '1px solid #95E6A2',
                backgroundColor: '#46D45D',
              },
              value: '500',
            },
            {
              title: ' Today On-going',
              icon: SettingIcon,
              style: {
                border: '1px solid #F9CD9F',
                backgroundColor: '#F5A859',
              },
              value: '10',
            },
            {
              title: 'Today Pending Invoice',
              icon: FileIcon,
              style: {
                border: '1px solid #9ED0F2',
                backgroundColor: '#56ADE9',
              },
              value: '5',
            },
          ].map((item, i) => (
            <Col key={i} lg={3} md={4} sm={6} xs={12} className='mb-3'>
              <OverviewCard
                style={item.style}
                icon={item.icon}
                title={item.title}
                value={item.value}
              />
            </Col>
          ))}
        </Row>
      </div>

      <div className='mt-4'>
        <h4>Yearly Activity </h4>
        <Row className='mt-3'>
          <Col lg={6} className='mb-3'>
            <BarGraphCard style={{ border: '1px solid #9ED0F2' }} />
          </Col>
          <Col lg={6} className='mb-3'>
            <BarGraphCard style={{ border: '1px solid #9ED0F2' }} />
          </Col>
        </Row>
      </div>

      <div className='mt-4'>
        <h4>Recent Activity </h4>
        <Table
          columns={columns}
          data={fakeData(5)}
          className='mb-5 mt-3 border-radius-10 black-shadow-15'
          style={{ border: '1px solid #9ED0F2' }}
        />
      </div>
    </Container>
  )
}
