import React from 'react'
import {
  Button,
  Col,
  Container,
  Form,
  Image,
  InputGroup,
} from 'react-bootstrap'
import moment from 'moment'
import { CLASSES, COLORS } from '../../../assets/styles/classes'
import MoreIcon from '../../../assets/icons/common/more.svg'
import EditIcon from '../../../assets/icons/common/edit.svg'
import DeleteIcon from '../../../assets/icons/common/delete.svg'
import PlusIcon from '../../../assets/icons/common/plus-white.svg'
import UserIcon from '../../../assets/icons/sidebar/users-1.svg'
import TicketIcon from '../../../assets/icons/sidebar/ticket.svg'
import DropDownIcon from '../../../assets/icons/common/down-arrow-solid.svg'


import Table from '../../../components/table/Table'
import { fakeData } from '../../../utils/faker'
import DropDownX from '../../../components/extras/DropDownX'
import SearchFilter from '../../../components/extras/SearchFilter'
import { useNavigate } from 'react-router-dom'

export default function BillingDetails() {
  const navigate = useNavigate()
  const columns = [
    {
      name: 'Service',
      // width: '150px',
      style: {
        fontWeight: 'bold',
      },
      cell: ({ title }) => title || 'Item Name',
      conditionalCellStyles: [
        {
          when: row => {
            console.log(row)
            return row.title === 'Subtotal'
          },
          style: {
            color: COLORS.orange,
          },
        },
        {
          when: row => {
            console.log(row)
            return row.title === 'Total Due'
          },
          style: {
            color: COLORS.orange,
          },
        },
      ],
    },
    {
      name: 'Qty',
      minWidth: '180px',
      cell: ({ title }) => !title ? '1' : '',
    },
    {
      name: 'Rate',
      minWidth: '180px',
      cell: ({ title }) => !title ? '$3,000.00' : '',
    },
    {
      name: 'Guarantee',
      minWidth: '180px',
      cell: ({ title }) => !title ? '2 year' : '',
    },
    {
      name: 'Line total',
      minWidth: '180px',
      cell: ({ amount }) => amount || '$3,000.00',
      conditionalCellStyles: [
        {
          when: row => {
            console.log(row)
            return row.title === 'Total Due'
          },
          style: {
            color: COLORS.orange,
          },
        },
      ],
    },
  ];

  const conditionalRowStyles = [
    {
      when: row => {
        console.log(row)
        return row.title === 'Subtotal'
      },
      style: {
        borderTop: `1px solid #C8C8C8`,
      },
    },
    {
      when: row => {
        console.log(row)
        return row.title === 'Total Due'
      },
      style: {
        borderTop: `1px solid #C8C8C8`,
      },
    },
  ];

  return (
      <div className='my-5'>
        <div className={CLASSES.content_between}>
          <h4 className={CLASSES.items_center}>Billing Details</h4>
        </div>

        <div className='mb-5 mt-3 border-radius-10 black-shadow-15'>
          <Table
            columns={columns}
            data={[
              ...fakeData(3),
              {title: 'Subtotal', amount: '$9,000.00'},
              {title: 'Discount', amount: '$9,000.00'},
              {title: 'Service Charge', amount: '$9,000.00'},
              {title: 'Tax (5%)', amount: '$9,000.00'},
              {title: 'Total Due', amount: '$3,000.00'},
            ]}
            pagination={false}
            customStyle={{
              rows: {
                style: {
                  borderTop: '0px solid #E0E0E0',
                  borderBottom: '0px solid #E0E0E0 !important',
                  display: 'flex !important',
                  alignItems: 'center !important',
                  padding: 15,
                },
              },
            }}
            conditionalRowStyles={conditionalRowStyles}
          />
        </div>
      </div>
  )
}
