import React from 'react'

const data = [
  'All',
 ' Pending',
  'Waiting approval',
  'On Going',
  'Waiting for parts',
  'Waiting for Customer',
  'Closed',
  'Canceled',
]
function HorizontalNavbar({ src, title }) {
  const [index , setIndex] = React.useState(0)
  return (
    <div style={{color: '#676767'}} className='d-flex align-items-center justify-content-between my-4'>
      {data.map((item, i) => (
        <div
          onClick={() => setIndex(i)}
          style={{
            color: i === index ? '#288BCF' : '#676767',
            borderBottom: i === index ? '4px solid #288BCF': 0,
            marginBottom:  i === index ? -3 : 0,
            cursor: 'pointer',
          }}
          className='p-2 mx-2 text-center'
        >
          {item}
        </div>
      ))}
      
    </div>
  )
}

export default HorizontalNavbar
