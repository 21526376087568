import React from 'react'
import { CLASSES } from '../../../assets/styles/classes'
import { Image } from 'react-bootstrap'

export default function OverviewCard({style, icon, title, value}) {
  const [isHovered, setIsHovered] = React.useState(false);
  return (
    <div
      className={'border-radius-10 black-shadow-15 overflow-hidden h-100'}
      style={{...style, cursor: isHovered ? 'pointer' : 'initial'}}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >

      <div className={CLASSES.items_center+'p-3 py-5 me-2 bg-white h-100'}>
        <Image src={icon} />
        <div className='ms-4'>
          <h2 style={{color: style.backgroundColor}}>{value}</h2>
          {title}
        </div>
      </div>


    </div>
  )
}
