import React from 'react'
import { Col, Image } from 'react-bootstrap'
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';

function CustomerCardInfo({ fieldName, fieldValue}) {
    return (
        <Row className='mb-3'>
            <Col xs={6} md={6}>
                <Card.Text>{fieldName}: </Card.Text>
            </Col>
            <Col xs={6} md={6}>
                <Card.Text> {fieldValue} </Card.Text>
            </Col>
        </Row>
    )
}

export default CustomerCardInfo