import Tickets from '../pages/ticket/pages/Tickets'
import CreateTicket from '../pages/ticket/pages/CreateTicket';
import TicketDetails from '../pages/ticket/pages/TicketDetails';


const TicketsRoutes = [
  {
    path: '/ticket',
    component: Tickets,
  },
  {
    path: '/ticket/create',
    component: CreateTicket,
  },
  {
    path: '/ticket/edit/:id',
    component: CreateTicket,
  },
  {
    path: '/ticket/details/:id',
    component: TicketDetails,
  },
];

export default TicketsRoutes
