import React from 'react'
import {
  Button,
  ButtonGroup,
  Col,
  Container,
  Form,
  Image,
  InputGroup,
} from 'react-bootstrap'
import moment from 'moment'
import 'flatpickr/dist/flatpickr.css'
import Flatpickr from 'react-flatpickr'
import 'flatpickr/dist/plugins/monthSelect/style.css'
//
import { CLASSES } from '../../../assets/styles/classes'
import MoreIcon from '../../../assets/icons/common/more.svg'
import EyeIcon from '../../../assets/icons/common/eye.svg'
import EditIcon from '../../../assets/icons/common/edit.svg'
import DeleteIcon from '../../../assets/icons/common/delete.svg'
import PlusIcon from '../../../assets/icons/common/plus-white.svg'
import UserIcon from '../../../assets/icons/sidebar/users-1.svg'
import TicketIcon from '../../../assets/icons/sidebar/ticket.svg'
import DropDownIcon from '../../../assets/icons/common/down-arrow-solid.svg'
import CalendarWhiteIcon from '../../../assets/icons/sidebar/calendar-minus.svg'
//
import DateIcon from '../../../assets/icons/common/date.svg'

import Table from '../../../components/table/Table'
import { fakeData } from '../../../utils/faker'
import DropDownX from '../../../components/extras/DropDownX'
import SearchFilter from '../../../components/extras/SearchFilter'
import { useNavigate } from 'react-router-dom'
import AppointmentDetailsSidebar from '../components/AppointmentDetailsSidebar'
import ActionElements from '../../../components/extras/ActionElements'

export default function Appointments() {
  const navigate = useNavigate()
  const [isShowDetail, setShowDetails] = React.useState(false)
  const columns = [
    {
      name: 'Time',
      width: '120px',
      style: {
        fontWeight: 'bold',
      },
      style: { borderRight: '2px solid #C8C8C8' },
      cell: ({ time }) => (
        <div className='bg-blue text-white p-2'>10:00 AM</div>
      ),
    },
    {
      name: 'Appointment Id',
      width: '150px',
      style: {
        fontWeight: 'bold',
      },
      cell: ({ userId }) => userId.substr(0, 8),
    },
    {
      name: 'Customer Name',
      minWidth: '180px',
      cell: ({ username }) => username,
    },
    {
      name: 'Email',
      minWidth: '250px',
      cell: ({ email }) => email,
    },
    {
      name: 'Phone Number',
      minWidth: '180px',
      cell: ({}) => `01${Math.round(Math.random() * 1000000000)}`,
    },
    {
      name: 'Date',
      minWidth: '130px',
      cell: ({ birthdate }) => moment(birthdate).format('DD MMM, YYYY'),
    },
    {
      name: 'Type',
      width: '110px',
      center: true,
      cell: ({}) => (
        <div
          style={{ borderRadius: 20, backgroundColor: '#FEF4EA' }}
          className={CLASSES.items_center + 'p-1 px-2 text-orange'}
        >
          Visiting
        </div>
      ),
    },
    {
      name: 'Status',
      minWidth: '150px',
      center: true,
      cell: ({}) => (
        <div
          style={{ borderRadius: 20, border: '1px solid #F3922F' }}
          className={CLASSES.items_center + 'p-1 px-2 text-orange'}
        >
          On Going
        </div>
      ),
    },
    {
      name: 'Actions',
      width: '120px',
      style: { paddingRight: '30px' },
      center: true,
      allowOverflow: true,
      cell: (row) => (
        <ActionElements
          id={1}
          editClick={(id) => navigate(`/appointment/edit/${id}`)}
          viewClick={setShowDetails}
        />
      ),
    },
  ]

  return (
    <Container fluid className='p-4 py-2 mt-2'>
      <div>
        <div className={CLASSES.content_between}>
          <h4 className={CLASSES.items_center}>
            {' '}
            <Image src={CalendarWhiteIcon} className='me-3' /> Appointments
          </h4>
          <Button
            onClick={() => navigate('/appointment/create')}
            className={CLASSES.items_center + 'bg-blue'}
          >
            <Image src={PlusIcon} className={'me-2'} /> Add New Appointment
          </Button>
        </div>

        <div className='mb-5 mt-3 pt-4 border-radius-10 black-shadow-15'>
          <div className={CLASSES.content_between + 'px-4 mb-4'}>
            <div className='px-0 px-md-2'>
              <InputGroup>
                <Flatpickr
                  className='form-control'
                  placeholder={'DD-MM-YYY'}
                  options={{
                    disableMobile: true,
                    // position: 'below center',
                    // enableTime,
                    // minuteIncrement: 1,
                    // minDate,
                    // maxDate,
                    // dateFormat,
                  }}
                />
                <InputGroup.Text className=''>
                  <Image src={DateIcon} />
                </InputGroup.Text>
              </InputGroup>
            </div>

            <h4>25 April 2024</h4>

            <ButtonGroup>
              <Button variant='warning' className='text-white'>
                Daily
              </Button>
              <Button variant='' className='text-black'>
                Weekly
              </Button>
              <Button variant='' className='text-black'>
                Monthly
              </Button>
            </ButtonGroup>
          </div>

          <Table
            columns={columns}
            data={fakeData(10)}
            customStyle={{
              rows: {
                style: {
                  borderTop: '0px solid #E0E0E0',
                  borderBottom: '1px solid #E0E0E0 !important',
                  display: 'flex !important',
                  alignItems: 'center !important',
                  padding: 0,
                },
              },
            }}
          />
        </div>
      </div>
      <AppointmentDetailsSidebar show={isShowDetail} setShow={setShowDetails} />
    </Container>
  )
}
