import { FormGroup, Col, Form, FormLabel } from 'react-bootstrap'
import { Controller } from 'react-hook-form'
//
import LabelErrors from '../LabelErrors'

const ReactSelect = ({
  label = '',
  name = '',
  defaultValue = undefined,
  options = [],
  control = {},
  isDisabled = false,
  placeholder = '',
  errorMessage = '',
  noGrid = false,
  colClass = '',
  formGroupClass = '',
  xxl = '4',
  xl = '4',
  lg = '4',
  md = '6',
  sm = '12',
  xs = '12',
}) => {
  const renderInput = () => (
    <>
      {label && <FormLabel for={name}>{label}</FormLabel>}
      <Controller
        name={name}
        defaultValue={defaultValue}
        render={({ field }) => (
          <Form.Select
            {...field}
            options={options}
            className='react-select'
            isClearable
            placeholder={placeholder || 'Select'}
            isDisabled={isDisabled}
            aria-label='Default select example'
          >
            <option>Open this select menu</option>
            <option value='1'>One</option>
            <option value='2'>Two</option>
            <option value='3'>Three</option>
          </Form.Select>
        )}
        control={control}
      />
      <LabelErrors name={name} errorMessage={errorMessage} />
    </>
  )

  return (
    <>
      {noGrid ? (
        renderInput()
      ) : (
        <Col
          className={colClass}
          xs={xs}
          sm={sm}
          md={md}
          lg={lg}
          xl={xl}
          xxl={xxl}
        >
          <FormGroup className={formGroupClass}>{renderInput()}</FormGroup>
        </Col>
      )}
    </>
  )
}

export default ReactSelect
