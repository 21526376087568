function UserMenu({setShowMenu}) {
  return (
    <div className='user-menu p-4 d-flex flex-column'>
      <div className='text-center p-2' style={{backgroundColor: 'rgba(253, 222, 197, 1)'}}>
        Logout
      </div>
    </div>
  )
}

export default UserMenu
