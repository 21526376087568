/* eslint-disable react/prop-types */
import React, { useMemo, useState, useEffect, useCallback } from 'react'
import { Row, Col, Form, Card, Button, FormGroup, Image } from 'react-bootstrap'
import { useForm, useFieldArray } from 'react-hook-form'
// import {
//   useSelector,
//   useDispatch,
// } from 'react-redux'
// import cleanDeep from 'clean-deep'
// import { useHistory } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import ReactstrapInput from '../../../components/form/input-fields/ReactstrapInput'
//
import PlusIcon from '../../../assets/icons/common/plus-white.svg'
import CancelIcon from '../../../assets/icons/common/cancel.svg'
import ProgressIcon from '../../../assets/icons/common/progress.svg'
import DownloadedIcon from '../../../assets/icons/common/downloaded.svg'
import UserIcon from '../../../assets/icons/sidebar/users-1.svg'
import TicketIcon from '../../../assets/icons/sidebar/ticket.svg'

import { CLASSES } from '../../../assets/styles/classes'
import ReactstrapInputGroup from '../../../components/form/input-fields/ReactstrapInputGroup'
import ReactSelect from '../../../components/form/input-fields/ReactSelect'
import DatePicker from '../../../components/form/input-fields/DatePicker'
import TimePicker from '../../../components/form/input-fields/TimePicker'
import BillingDetails from '../components/BillingDetails'

export default function CreateTicket({
  customerId,
  bloodGroupOptions,
  customerTypeOptions,
}) {
  // const dispatch = useDispatch()
  // const history = useHistory()
  const [csPhoto, setCSPhoto] = useState([])
  const [documents, setDocuments] = useState([])

  const {
    formState: { errors, isValid },
    watch,
    reset,
    control,
    setValue,
    handleSubmit,
  } = useForm({
    mode: 'all',
    defaultValues: {
      type_id: null,
      numbers: [{number: ''}],
    },
    // resolver: yupResolver(customerSchema),
  })

  const { remove, fields, append } = useFieldArray({
    name: 'numbers',
    control,
  })

  const onSubmit = async (values) => {
    return false
  }

  return (
    <div className='p-4'>
      <div className={CLASSES.content_between + 'mb-4'}>
        <h4 className={CLASSES.items_center}>
          {' '}
          <Image src={TicketIcon} className='me-3' /> Ticket Information
        </h4>
        <div style={{color: '#18C935'}}>Customer was successfully created.</div>
      </div>
      <Form className='d-flex flex-column justify-content-between'>
        <div className='bg-white card black-shadow-15 p-4 px-5'>
          <Row className='mb-3 text-bold'>
            <Col>New Ticket Create</Col>
          </Row>
          <Row className=''>
            <ReactstrapInput
              {...{
                label: 'Ticket Title',
                name: 'title',
                type: 'text',
                placeholder: 'Enter title',
                errorMessage: errors?.title?.message,
                control,
                lg: '8',
                xl: '8',
                xxl: '8',
              }}
            />
            <ReactSelect
              {...{
                label: 'Ticket Status',
                name: 'contact',
                type: 'text',
                placeholder: 'Enter customer last name',
                errorMessage: errors?.contact?.message,
                control,
              }}
            />
             <ReactSelect
              {...{
                label: 'Item name',
                name: 'contact',
                type: 'text',
                placeholder: 'Enter customer last name',
                errorMessage: errors?.contact?.message,
                control,
              }}
            />
             <ReactSelect
              {...{
                label: 'Issue type',
                name: 'contact',
                type: 'text',
                placeholder: 'Enter customer last name',
                errorMessage: errors?.contact?.message,
                control,
              }}
            />
             <ReactSelect
              {...{
                label: 'Ticket type',
                name: 'contact',
                type: 'text',
                placeholder: 'Enter customer last name',
                errorMessage: errors?.contact?.message,
                control,
              }}
            />
          </Row>
          <hr/>
          <Row className='my-4 text-bold'>
            <Col>Device Pick</Col>
          </Row>
          <Row>
            <DatePicker
              {...{
                label: 'Issue Date',
                name: 'address_one',
                type: 'text',
                placeholder: 'Write address 1',
                errorMessage: errors?.address_one?.message,
                control,
              }}
            />
            <TimePicker
              {...{
                label: 'Pick Time (approximate)',
                name: 'address_two',
                type: 'text',
                placeholder: 'Write address 2',
                errorMessage: errors?.address_one?.message,
                control,
              }}
            />
  
            <DatePicker
              {...{
                label: 'Analysis Date (approximate)',
                name: 'address_one',
                type: 'text',
                placeholder: 'Write address 1',
                errorMessage: errors?.address_one?.message,
                control,
              }}
            />
             <DatePicker
              {...{
                label: 'Repairing  Date (approximate)',
                name: 'address_one',
                type: 'text',
                placeholder: 'Write address 1',
                errorMessage: errors?.address_one?.message,
                control,
              }}
            />
             <DatePicker
              {...{
                label: 'Delivery  Date (approximate)',
                name: 'address_one',
                type: 'text',
                placeholder: 'Write address 1',
                errorMessage: errors?.address_one?.message,
                control,
              }}
            />
             <TimePicker
              {...{
                label: 'Delivery Time (approximate)',
                name: 'address_two',
                type: 'text',
                placeholder: 'Write address 2',
                errorMessage: errors?.address_one?.message,
                control,
              }}
            />
            <ReactstrapInput
              {...{
                as: 'textarea',
                rows: '8',
                name: 'credit_limit',
                label: 'Reason for Services',
                placeholder: 'Write here',
                errorMessage: errors?.credit_limit?.message,
                control,
              }}
            />
             <ReactstrapInput
              {...{
                type: 'file',
                rows: '8',
                name: 'credit_limit',
                label: 'Attachment (if have any file max 20 MB)',
                placeholder: 'Write here',
                errorMessage: errors?.credit_limit?.message,
                control,
              }}
            />
            <Col lg={4} md={6} >
              <div className='my-4 d-flex border'>
                <Image src={ProgressIcon} className='me-3' />
                <div className='text-bold d-flex align-items-center justify-content-between w-100'>
                  <div>
                      <div>File 1</div>
                      <div>Size 1 MB</div>
                  </div>
                  <Image height={40} src={CancelIcon} className='ms-3 pointer' />
                </div>
              </div>
              <div className='d-flex border'>
                <Image src={DownloadedIcon} className='me-3' />
                <div className='text-bold d-flex align-items-center justify-content-between w-100'>
                  <div>
                      <div>File 2</div>
                      <div>Size 1.5 MB</div>
                  </div>
                  <Image height={40} src={CancelIcon} className='ms-3 pointer' />
                </div>
              </div>
            </Col>
          </Row>
          <hr />
          <Row>
            <ReactSelect
              {...{
                label: 'Add Assets',
                name: 'contact',
                type: 'text',
                placeholder: 'Enter customer last name',
                errorMessage: errors?.contact?.message,
                control,
              }}
            />
          </Row>
          <Row>
            <ReactstrapInput
              {...{
                type: 'number',
                disabled: watch('cash'),
                name: 'credit_limit',
                label: 'Phone/Email id',
                placeholder: 'Write here',
                errorMessage: errors?.credit_limit?.message,
                control,
              }}
            />
          </Row>
        </div>
        <BillingDetails />
        <Row className='mt-5'>
          <Col className='d-flex mt-1 mx-5'>
            <FormGroup className='w-100'>
              <Button
                className='mr-0 w-100 bg-blue border-0'
                color='primary'
                onClick={handleSubmit(onSubmit)}
              >
                Submit
              </Button>
            </FormGroup>
          </Col>
        </Row>
      </Form>
    </div>
  )
}
