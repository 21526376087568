import {
  Card,
  CardBody,
} from 'react-bootstrap'

const CardX = (props) => (
  <Card border={props.border}>
    <CardBody className="my-1">
      {props.children}
    </CardBody>
  </Card>
)
export default CardX
