import React from 'react'
import {
  Button,
  Col,
  Container,
  Form,
  Image,
  InputGroup,
} from 'react-bootstrap'
import moment from 'moment'
import { CLASSES } from '../../../assets/styles/classes'
import MoreIcon from '../../../assets/icons/common/more.svg'
import EditIcon from '../../../assets/icons/common/edit.svg'
import DeleteIcon from '../../../assets/icons/common/delete.svg'
import DownloadIcon from '../../../assets/icons/common/download.svg'
import PlusIcon from '../../../assets/icons/common/plus-white.svg'
import UserIcon from '../../../assets/icons/sidebar/users-1.svg'
import TicketIcon from '../../../assets/icons/sidebar/ticket.svg'
import DropDownIcon from '../../../assets/icons/common/down-arrow-solid.svg'

import Table from '../../../components/table/Table'
import { fakeData } from '../../../utils/faker'
import DropDownX from '../../../components/extras/DropDownX'
import SearchFilter from '../../../components/extras/SearchFilter'
import { useNavigate } from 'react-router-dom'
import HorizontalNavbar from '../components/HorizontalNavbar'
import HeaderInfo from '../../../components/extras/HeaderInfo'
import BillingDetails from '../components/BillingDetails'
import CardX from '../../../components/layout/CardX'
import RelevantAssets from '../components/RelevantAssets'

 const TicketDetails = React.forwardRef(({type = 'page'}, ref) => {
  const navigate = useNavigate()

  return (
    <Container ref={ref} fluid className='p-4 py-2 mt-2'>
      <CardX border={type === 'modal' ? '0' : '1'}>
        <div className={CLASSES.content_between}>
          <h4 className={CLASSES.items_center}>
            {' '}
            <Image src={TicketIcon} className='me-3' />
            Ticket: 25165456152
          </h4>
          {/* <div className={CLASSES.items_center + 'text-bold'}>
             Total Ticket: 500
           </div> */}
        </div>

        <h6 className={CLASSES.items_center + 'text-orange my-4'}>
          On going
        </h6>

        <div
          style={{ borderBottom: '1px solid #C8C8C8' }}
          className={CLASSES.content_between + 'my-3'}
        >
          <h5 style={{ color: '#464646' }} className={CLASSES.items_center}>
            Ticket Details
          </h5>
          <div className={CLASSES.items_center + 'text-bold'}>
            <Image src={EditIcon} className='me-2' />
            Edit
          </div>
        </div>

        <ul style={{ color: '#373737' }} className='p-0'>
          {[
            { label: 'Customer Name:', value: 'Mr. Honey' },
            { label: 'Customer Id:', value: '25895552' },
            { label: 'Item Name:', value: 'laptop' },
            { label: 'Issue Type:', value: 'Software' },
            { label: 'Ticket type:', value: 'Repair' },
            { label: 'Issue Date:', value: '12/8/23' },
            { label: 'Pick Time:', value: '12:30PM -5:00 PM' },
            { label: 'Analysis Date:', value: '12/8/23' },
            { label: 'Issue Date:', value: '12/8/23' },
          ].map((item, i) => (
            <li className={CLASSES.items_center+ 'gap-3 my-3'} key={i}>
              <div >{item.label}</div>
              <div>{item.value}</div>
            </li>
          ))}
        </ul>

        <div className='my-5'>
          <h5>Note:</h5>
          <p style={{ maxWidth: 500 }}>
            In publishing and graphic design, Lorem ipsum is a placeholder text
            commonly used to demonstrate the visual form of a document or a
            typeface without relying on meaningful content.
          </p>
        </div>

        <div>
          <h5>Attachment</h5>
          <div className='mt-3'>
            <input type='button' value={'document-name.PDF'} />
            <Image src={DownloadIcon} className='ms-3' />
          </div>
        </div>

        <div className='pb-4'>
          <RelevantAssets />
        </div>
        <BillingDetails />
      </CardX>
    </Container>
  )
});
export default TicketDetails;