import Offcanvas from 'react-bootstrap/Offcanvas'
import { Button, Image } from 'react-bootstrap'
//
import { CLASSES } from '../../../assets/styles/classes'
import EditIcon from '../../../assets/icons/common/edit.svg'

function AppointmentDetailsSidebar({ show, setShow }) {
  return (
    <>
      <Offcanvas placement='end' show={show} onHide={() => setShow(false)}>
        <Offcanvas.Header closeButton className='black-shadow-15'>
          <Offcanvas.Title >Appointment Details</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className={CLASSES.content_between + 'my-3'}>
            <h6 style={{ color: '#464646' }} className={CLASSES.items_center}>
              Customer Details{' '}
            </h6>
            <div className={CLASSES.items_center + 'text-bold'}>
              <Image src={EditIcon} className='me-2' />
            </div>
          </div>

          <div className={CLASSES.content_between + 'text-white bg-blue p-3'}>
            <div>
              <div className='text-bold mb-2'>Appointment ID:</div>
              <div>AWE785963</div>
            </div>
            <div>
              <div className='text-bold mb-2'>Time:</div>
              <div>10:00 PM</div>
            </div>
          </div>

          <ul style={{ color: '#373737' }} className='p-0'>
            {[
              { label: 'Customer Name:', value: 'Mr. Honey' },
              { label: 'Customer Id:', value: '25895552' },
              { label: 'Item Name:', value: 'laptop' },
              { label: 'Issue Type:', value: 'Software' },
              { label: 'Ticket type:', value: 'Repair' },
              { label: 'Issue Date:', value: '12/8/23' },
              { label: 'Pick Time:', value: '12:30PM -5:00 PM' },
              { label: 'Analysis Date:', value: '12/8/23' },
              { label: 'Issue Date:', value: '12/8/23' },
            ].map((item, i) => (
              <li className={CLASSES.items_center + 'gap-3 my-2'} key={i}>
                <div className='text-bold'>{item.label}</div>
                <div>{item.value}</div>
              </li>
            ))}
          </ul>

          <div className='my-5'>
            <h5>Note:</h5>
            <p style={{ maxWidth: 500 }}>
              In publishing and graphic design, Lorem ipsum is a placeholder
              text commonly used to demonstrate the visual form of a document or
              a typeface without relying on meaningful content.
            </p>
          </div>

          <div className='d-grid gap-2'>
            <Button variant='success' size='md' style={{borderRadius: 0}}>
              Complete
            </Button>
            <Button variant='danger' size='md' style={{borderRadius: 0}}>
              Reject
            </Button>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  )
}

export default AppointmentDetailsSidebar
