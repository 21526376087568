import React from 'react'
import CardElement from './CardElement'

function CustomerInvoice({data}) {
  // loop through data and print elements - in development
  return (
    <div className='w-100'>
        <CardElement data={{ 'status': 1 }} page='invoice' />
        <CardElement data={{ 'status': 0 }} page='invoice'/>
    </div>
  )
}

export default CustomerInvoice