import Admin from '../pages/admin/pages/Admin'
import CreateAdmin from '../pages/admin/pages/CreateAdmin';
import AdminDetails from '../pages/admin/pages/AdminDetails';


const AdminRoutes = [
  {
    path: '/admin',
    component: Admin,
  },
  {
    path: '/admin/create',
    component: CreateAdmin,
  },
  {
    path: '/admin/edit/:id',
    component: CreateAdmin,
  },
  {
    path: '/admin/details/:id',
    component: AdminDetails,
  },
];

export default AdminRoutes
