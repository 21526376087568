import React from 'react'
import { Form } from 'react-bootstrap';

function SelectStatusElement({ data, page }) {

    const dropColors = {
        yellow: '#f0ad4e',
        red: '#dc3545'
    };
    const dropStatus = {
        onGoing: 1,
        closed: 0
    }
    const [dropProps, setDropProps] = React.useState({
        color: data.status === 1 ? dropColors.yellow : dropColors.red,
        active: data.status
    })

    const StatusChange = (event) => {
        const selectedValue = event.target.value
        if (selectedValue == dropStatus.onGoing) { setDropProps({ color: dropColors.yellow }); }
        else if (selectedValue == dropStatus.closed) { setDropProps({ color: dropColors.red }) }

        // start Api request here - to change status
        // use "page" to indentify where to send request
    }

    return (
        <Form.Select onChange={StatusChange} size="sm" style={{ fontSize: '14px', color: dropProps.color, border: `2px solid ${dropProps.color}`, borderRadius: '15px' }} >

            {dropProps.active == dropStatus.onGoing ? <option value={dropStatus.onGoing} selected>On going</option> : <option value={dropStatus.onGoing}>on going</option>}
            {dropProps.active == dropStatus.closed ? <option value={dropStatus.closed} selected>Closed</option> : <option value={dropStatus.closed}>Closed</option>}

        </Form.Select>
    )
}

export default SelectStatusElement