/* eslint-disable react/prop-types */
import React, { useMemo, useState, useEffect, useCallback } from 'react'
import { Row, Col, Form, Card, Button, FormGroup, Image } from 'react-bootstrap'
import { useForm, useFieldArray } from 'react-hook-form'
// import {
//   useSelector,
//   useDispatch,
// } from 'react-redux'
// import cleanDeep from 'clean-deep'
// import { useHistory } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import ReactstrapInput from '../../../components/form/input-fields/ReactstrapInput'
//
import PlusIcon from '../../../assets/icons/common/plus-white.svg'
import PlusBlackIcon from '../../../assets/icons/common/plus-black.svg'
import UserIcon from '../../../assets/icons/sidebar/users-1.svg'

import { CLASSES } from '../../../assets/styles/classes'

export default function CreateAdmin({
  customerId,
  bloodGroupOptions,
  customerTypeOptions,
}) {
  // const dispatch = useDispatch()
  // const history = useHistory()
  const [csPhoto, setCSPhoto] = useState([])
  const [documents, setDocuments] = useState([])

  const {
    formState: { errors, isValid },
    watch,
    reset,
    control,
    setValue,
    handleSubmit,
  } = useForm({
    mode: 'all',
    defaultValues: {
      type_id: null,
      number: '',
    },
    // resolver: yupResolver(customerSchema),
  })


  const onSubmit = async (values) => {
    return false
  }

  return (
    <div className='p-4'>
      <div className={CLASSES.content_between + 'mb-4'}>
        <h4 className={CLASSES.items_center}>
          {' '}
          <Image src={UserIcon} className='me-3' /> Admin Information
        </h4>
      </div>
      <Form className='d-flex flex-column justify-content-between'>
        <div className='bg-white card black-shadow-15 p-4 px-5'>
          <Row className='mb-3 text-bold'>
            <Col>Personal info.</Col>
          </Row>
          <Row className=''>
            <ReactstrapInput
              {...{
                label: 'First Name',
                name: 'name',
                type: 'text',
                placeholder: 'Enter customer first name',
                errorMessage: errors?.name?.message,
                control,
              }}
            />
            <ReactstrapInput
              {...{
                label: 'Last Name',
                name: 'contact',
                type: 'text',
                placeholder: 'Enter customer last name',
                errorMessage: errors?.contact?.message,
                control,
              }}
            />

            </Row>
            <Row>
            <ReactstrapInput
              {...{
                label: 'Email id',
                name: 'email',
                type: 'email',
                placeholder: 'Enter customer Email id',
                errorMessage: errors?.email?.message,
                control,
              }}
            />
              <ReactstrapInput
                {...{
                  label: 'Phone Number',
                  name:'number',
                  type: 'number',
                  placeholder: 'Enter customer Phone number',
                  errorMessage: errors?.number?.message,
                  control,
                }}
              />
          </Row>
          <Row className='my-4 text-bold'>
            <Col>Login Info</Col>
          </Row>
          <Row>
            <ReactstrapInput
              {...{
                label: 'User Name',
                name: 'address_one',
                type: 'text',
                placeholder: 'Write username',
                errorMessage: errors?.address_one?.message,
                control,
              }}
            />
            <ReactstrapInput
              {...{
                label: 'Password',
                name: 'address_one',
                type: 'text',
                placeholder: 'Write password',
                errorMessage: errors?.address_one?.message,
                control,
              }}
            />
            
          </Row>
        </div>
        <Row className='mt-5'>
          <Col className='d-flex mt-1 mx-5'>
            <FormGroup className='w-100'>
              <Button
                className='mr-0 w-100 bg-blue border-0'
                color='primary'
                onClick={handleSubmit(onSubmit)}
              >
                Submit
              </Button>
            </FormGroup>
          </Col>
        </Row>
      </Form>
    </div>
  )
}
