const CustomStylesDataTable = {
  rows: {
    style: {
      borderBottom: '1px solid #E0E0E0',
      // paddingTop: '10px !important',
      // paddingBottom: '10px !important',
      display: 'flex !important',
      alignItems: 'center !important',
    },
  },
  headCells: {
    style: {
      paddingLeft: '28px !important', // override the cell padding for head cells
      whiteSpace: 'nowrap !important',
      // paddingTop: '14px !important',
      // paddingBottom: '14px !important',
      fontWeight: '500 !important',
      fontSize: '1rem',
    },
  },
  cells: {
    style: {
      color: ' #6e6b7b',
      fontWeight: '500 !important',
      paddingTop: '5px !important',
      paddingBottom: '5px !important',
      fontSize: '1rem',
      // paddingLeft: '28px !important',
      height: '100% !important',
    },
  },
};
export default CustomStylesDataTable
