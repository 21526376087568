import React from 'react'
import { Button, Card, Col, Image, Row } from 'react-bootstrap'
import DeleteIcon from '../../../assets/icons/common/delete.svg'
import Badge from 'react-bootstrap/Badge';
import { CLASSES, COLORS } from '../../../assets/styles/classes';
import CommunicateModal from './CommunicateModal';

function CustomerCommunicate({ data }) {

    const TimeElements = ({ element }) => {
        return (
            <div className='d-flex align-items-center justify-content-center '>
                <div style={styles.round} />
                <span style={{ color: '#888888', marginLeft: '10px' }}>{element}</span>
            </div>
        )
    }

    const CardTopElements = ({ time, date, method, id }) => {

        return (
            <div style={{ ...styles.fontSize14, display: 'flex', alignItems: 'center', alignSelf: 'center', justifyContent: 'space-evenly' }}>
                <TimeElements element={time} />
                <TimeElements element={date} />

                <div>
                    <Badge className='px-3 py-2 text-black' style={{ fontSize: '11px', backgroundColor: COLORS.badge }} bg={COLORS.badge}>{method}</Badge>
                </div>

                <div>
                    <Button variant='white' className=' btn-sm'>
                        <Image width={'20px'} height={'20px'} src={DeleteIcon} />
                    </Button>
                </div>

            </div>
        );
    }

    const CommunicateCards = ({ data }) => {
        return (
            <Card className='w-100 shadow-basic border-1 mb-3'>
                <Card.Body className='w-100'>
                    <Row>
                        <Col xs={12} md={6}>
                            <div className='d-flex flex-column justify-content-start align-items-start'>
                                <div style={{ ...styles.fontSize14, fontWeight: '500' }} >Mr. Hanny (email@gmail.com)</div>
                                <div style={{ fontSize: '11px' }}>
                                    <TimeElements element={'Send by Admin ID :568565'} />
                                </div>
                            </div>

                        </Col>

                        <Col xs={12} md={6}>
                            <CardTopElements time={'10:00 AM'} date={'25 Feb, 2023'} method={'mail'} id={'1'} />
                        </Col>

                    </Row>
                </Card.Body>

                <Card.Body className='pb-4'>

                    In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content.

                </Card.Body>
            </Card>
        )
    }

    return (
        <>
            <CommunicateModal />
            <CommunicateCards data={data} />
            <CommunicateCards data={data} />
            <CommunicateCards data={data} />
        </>
    )
}
const styles = {
    fontSize14: {
        fontSize: '14px',
    },
    round: {
        borderRadius: '50%',
        height: '5px',
        width: '5px',
        backgroundColor: '#888888',
    }
}

export default CustomerCommunicate