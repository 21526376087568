import Container from 'react-bootstrap/Container'
import { Link } from 'react-router-dom'

function PageNotFound() {
  return (
    <Container className='p-4 mt-4 d-flex flex-column justify-content-center align-items-center'>
      <div class='error-container'>
        <div class='error-code'>404</div>
        <div class='error-message'>Oops! Page not found</div>
        <Link to='/' class='back-to-home'>
          Back to Home
        </Link>
      </div>
    </Container>
  )
}

export default PageNotFound
