/* eslint-disable react/prop-types */
import React, { useMemo, useState, useEffect, useCallback } from 'react'
import { Row, Col, Form, Card, Button, FormGroup, Image, InputGroup, FormControl } from 'react-bootstrap'
import { useForm, useFieldArray } from 'react-hook-form'
// import {
//   useSelector,
//   useDispatch,
// } from 'react-redux'
// import cleanDeep from 'clean-deep'
// import { useHistory } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import ReactstrapInput from '../../../components/form/input-fields/ReactstrapInput'
//
import PlusIcon from '../../../assets/icons/common/plus-white.svg'
import PlusBlackIcon from '../../../assets/icons/common/plus-black.svg'
import UserIcon from '../../../assets/icons/sidebar/users-1.svg'

import { CLASSES } from '../../../assets/styles/classes'

export default function CreateCustomerAsset({
  customerId,
  bloodGroupOptions,
  customerTypeOptions,
}) {
  // const dispatch = useDispatch()
  // const history = useHistory()
  const [csPhoto, setCSPhoto] = useState([])
  const [documents, setDocuments] = useState([])

  const {
    formState: { errors, isValid },
    watch,
    reset,
    control,
    setValue,
    handleSubmit,
  } = useForm({
    mode: 'all',
    defaultValues: {
      type_id: null,
      number: '',
    },
    // resolver: yupResolver(customerSchema),
  })


  const onSubmit = async (values) => {
    return false
  }

  return (
    <div className='p-4'>
      <div className={CLASSES.content_between + 'mb-4'}>
        <h4 className={CLASSES.items_center}>
          {' '}
          <Image src={UserIcon} className='me-3' /> Asset Information
        </h4>
      </div>
      <Form className='d-flex flex-column justify-content-between'>
        <div className='bg-white card black-shadow-15 p-4 px-5'>
          <Row className='mb-3 text-bold'>
            <Col>Asset info.</Col>
          </Row>
          <Row className=''>
            <ReactstrapInput
              {...{
                label: 'Asset Name',
                name: 'name',
                type: 'text',
                placeholder: 'Enter asset name',
                errorMessage: errors?.name?.message,
                control,
              }}
            />
            <ReactstrapInput
              {...{
                label: 'Brand Name',
                name: 'contact',
                type: 'text',
                placeholder: 'Enter brand name',
                errorMessage: errors?.contact?.message,
                control,
              }}
            />
            <ReactstrapInput
              {...{
                label: 'Model',
                name: 'contact',
                type: 'text',
                placeholder: 'Enter model',
                errorMessage: errors?.contact?.message,
                control,
              }}
            />

            </Row>
            <Row>
            <ReactstrapInput
              {...{
                label: 'Quantity',
                name: 'email',
                type: 'email',
                placeholder: 'Enter quantity',
                errorMessage: errors?.email?.message,
                control,
              }}
            />
            <Col lg={4} md={12} className={CLASSES.items_center}>
              <ReactstrapInput
                {...{
                  label: 'Guarantee',
                  name:'number',
                  type: 'number',
                  placeholder: 'Enter Guarantee',
                  errorMessage: errors?.number?.message,
                  control,
                  xs: '4',
                  sm: '6',
                  md: '6',
                  lg: '6',
                }}
              />
              <div className={CLASSES.content_center + 'mt-2 ms-4'}>
                  <input type='radio' />
                <div className='ms-2'>Month</div>
              </div>
              <div className={CLASSES.content_center + 'mt-2 ms-4'}>
                  <input type='radio' />
                <div className='ms-2'>Year</div>
              </div>
            </Col>
          </Row>
        </div>
        <Row className='mt-5'>
          <Col className='d-flex mt-1 mx-5'>
            <FormGroup className='w-100'>
              <Button
                className='mr-0 w-100 bg-blue border-0'
                color='primary'
                onClick={handleSubmit(onSubmit)}
              >
                Submit
              </Button>
            </FormGroup>
          </Col>
        </Row>
      </Form>
    </div>
  )
}
