/* eslint-disable react/prop-types */
import React, { useMemo, useState, useEffect, useCallback } from 'react'
import { Row, Col, Form, Card, Button, FormGroup, Image } from 'react-bootstrap'
import { useForm, useFieldArray } from 'react-hook-form'
// import {
//   useSelector,
//   useDispatch,
// } from 'react-redux'
// import cleanDeep from 'clean-deep'
// import { useHistory } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import ReactstrapInput from '../../../components/form/input-fields/ReactstrapInput'
//
import CalendarWhiteIcon from '../../../assets/icons/sidebar/calendar-minus.svg'

import { CLASSES } from '../../../assets/styles/classes'
import DatePicker from '../../../components/form/input-fields/DatePicker'
import TimePicker from '../../../components/form/input-fields/TimePicker'

export default function CreateAppointment({
  customerId,
  bloodGroupOptions,
  customerTypeOptions,
}) {
  // const dispatch = useDispatch()
  // const history = useHistory()
  const [csPhoto, setCSPhoto] = useState([])
  const [documents, setDocuments] = useState([])

  const {
    formState: { errors, isValid },
    watch,
    reset,
    control,
    setValue,
    handleSubmit,
  } = useForm({
    mode: 'all',
    defaultValues: {
      appointment_id: '',
      customer_name: '',
      type_id: null,
      number: '',
    },
    // resolver: yupResolver(customerSchema),
  })

  const onSubmit = async (values) => {
    return false
  }

  return (
    <div className='p-4'>
      <div className={CLASSES.content_between + 'mb-4'}>
        <h4 className={CLASSES.items_center}>
          {' '}
          <Image src={CalendarWhiteIcon} className='me-3' /> Add New Appointment
        </h4>
      </div>
      <Form className='d-flex flex-column justify-content-between'>
        <div className='bg-white card border-0 black-shadow-15 p-4 px-5'>
          <Row className={CLASSES.content_center}>
            <Col lg={6} >
              <Row className=''>
                <ReactstrapInput
                  {...{
                    label: 'Appointment ID',
                    name: 'appointment_id',
                    type: 'text',
                    placeholder: 'Enter appointment ID (or generate automatically )',
                    errorMessage: errors?.appointment_id?.message,
                    control,
                    noGrid: true
                  }}
                />
                <ReactstrapInput
                  {...{
                    label: 'Customer Name',
                    name: 'customer_name',
                    type: 'text',
                    placeholder: 'Enter customer name',
                    errorMessage: errors?.customer_name?.message,
                    control,
                    noGrid: true
                  }}
                />
              </Row>
              <Row>
                <ReactstrapInput
                  {...{
                    label: 'Email id',
                    name: 'email',
                    type: 'email',
                    placeholder: 'Enter customer Email id',
                    errorMessage: errors?.email?.message,
                    control,
                    xxl: '6',
                    xl: '6',
                    lg: '6',
                  }}
                />
                <ReactstrapInput
                  {...{
                    label: 'Phone Number',
                    name: 'number',
                    type: 'number',
                    placeholder: 'Enter customer Phone number',
                    errorMessage: errors?.number?.message,
                    control,
                    xxl: '6',
                    xl: '6',
                    lg: '6',
                  }}
                />
              </Row>
              <Row>
                <DatePicker
                  {...{
                    label: 'Date',
                    name: 'date',
                    type: 'text',
                    placeholder: 'Select date',
                    errorMessage: errors?.date?.message,
                    control,
                    xxl: '6',
                    xl: '6',
                    lg: '6',
                  }}
                />
                <TimePicker
                  {...{
                    label: 'Time',
                    name: 'time',
                    type: 'text',
                    placeholder: 'Select time',
                    errorMessage: errors?.time?.message,
                    control,
                    xxl: '6',
                    xl: '6',
                    lg: '6',
                  }}
                />
              </Row>
              <Row>
                <ReactstrapInput
                  {...{
                    label: 'Type',
                    name: 'type',
                    type: 'text',
                    placeholder: 'Write type',
                    errorMessage: errors?.type?.message,
                    control,
                    xxl: '6',
                    xl: '6',
                    lg: '6',
                  }}
                />
                <ReactstrapInput
                  {...{
                    label: 'Status',
                    name: 'status',
                    type: 'text',
                    placeholder: 'Write status',
                    errorMessage: errors?.status?.message,
                    control,
                    xxl: '6',
                    xl: '6',
                    lg: '6',
                  }}
                />
              </Row>
              <Row>
                <ReactstrapInput
                  {...{
                    label: 'Note',
                    name: 'name',
                    type: 'text',
                    as: 'textarea',
                    placeholder: 'Write note',
                    errorMessage: errors?.name?.message,
                    control,
                    rows: '3',
                    noGrid: true,
                  }}
                />
              </Row>
              <Row className='mt-2'>
                <Col className='d-flex mt-1'>
                  <FormGroup className='w-100'>
                    <Button
                      className='mr-0 w-100 bg-blue border-0'
                      color='primary'
                      onClick={handleSubmit(onSubmit)}
                    >
                      Create New Appointment
                    </Button>
                  </FormGroup>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Form>
    </div>
  )
}
