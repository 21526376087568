import React from 'react'
import {
  Button,
  Col,
  Container,
  Form,
  Image,
  InputGroup,
} from 'react-bootstrap'
import moment from 'moment'
import { CLASSES } from '../../../assets/styles/classes'
import MoreIcon from '../../../assets/icons/common/more.svg'
import EditIcon from '../../../assets/icons/common/edit.svg'
import DeleteIcon from '../../../assets/icons/common/delete.svg'
import PlusIcon from '../../../assets/icons/common/plus-white.svg'
import UserIcon from '../../../assets/icons/sidebar/users-1.svg'
import TicketIcon from '../../../assets/icons/sidebar/ticket.svg'
import DropDownIcon from '../../../assets/icons/common/down-arrow-solid.svg'


import Table from '../../../components/table/Table'
import { fakeData } from '../../../utils/faker'
import DropDownX from '../../../components/extras/DropDownX'
import SearchFilter from '../../../components/extras/SearchFilter'
import { useNavigate } from 'react-router-dom'

export default function RelevantAssets() {
  const navigate = useNavigate()
  const columns = [
    {
      name: 'Asset Type',
      // width: '150px',
      style: {
        fontWeight: 'bold',
      },
      cell: ({ title }) => 'Fixed',
    },
    {
      name: 'Asset Name',
      minWidth: '180px',
      cell: ({ title }) => !title ? 'Item Name' : '',
    },
    {
      name: 'Asset Serial',
      minWidth: '180px',
      cell: ({ title }) => !title ? '$3,000.00' : '',
    },
    {
      name: 'Model',
      minWidth: '180px',
      cell: ({ title }) => !title ? 'A41524' : '',
    },
    {
      name: 'Guarantee',
      minWidth: '180px',
      cell: ({ title }) => !title ? '2 year' : '',
    },
    {
      name: 'Validity',
      minWidth: '180px',
      style: {color: 'red'},
      cell: ({ title }) => !title ? '2y 364 day left' : '',
    },
  ];

  return (
      <div className='my-5'>
        <div className={CLASSES.content_between}>
          <h5 className={CLASSES.items_center}>Relevant Assets</h5>
        </div>

        <div className='mb-5 mt-3 border-radius-10 black-shadow-15'>
          <Table
            columns={columns}
            data={[ ...fakeData(3) ]}
            pagination={false}
            customStyle={{
              rows: {
                style: {
                  borderTop: '0px solid #E0E0E0',
                  borderBottom: '0px solid #E0E0E0 !important',
                  display: 'flex !important',
                  alignItems: 'center !important',
                  padding: 15,
                },
              },
            }}
          />
        </div>
      </div>
  )
}
