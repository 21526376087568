import React from 'react'
import { CLASSES } from '../../assets/styles/classes'

function TabHeader({ Img, title, element }) {
  return (
    <div className={CLASSES.content_between}>
        <h4 className={CLASSES.items_center}>
          {' '}
          {Img}
          {title}
        </h4>
        
            {element}
     
      </div>
  )
}

export default TabHeader