import React from 'react'
import Card from 'react-bootstrap/Card';
import CustomerCardInfo from './CustomerCardInfo';
import { Button, Image } from 'react-bootstrap';
import EditIcon from '../../../assets/icons/common/edit.svg';

const CharacterCard = ({ data }) => {
  return (
    <Card style={{ width: '100%', fontSize: '14px' }}>
      <Card.Body>
        <Card.Subtitle className="text-muted">Overview</Card.Subtitle>
        <div className='my-3 d-flex justify-content-center mt-2'>
          <Card.Img style={{ borderRadius: '50%', height: '132px', width: '132px' }} rounded variant="top" src="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fdevelopers.elementor.com%2Fdocs%2Fassets%2Fimg%2Felementor-placeholder-image.png&f=1&nofb=1&ipt=14d5ab0300ae4a0f1624e024b1ea7c6e2d4ff1c853fb59c7535061e58f62651e&ipo=images" />
        </div>
        <hr />
        <div className='px-3 py-2' style={styles.border}>
          <CustomerCardInfo fieldName="Customer Name" fieldValue={data.name} />
          <CustomerCardInfo fieldName="Customer ID" fieldValue={data.id} />
          <CustomerCardInfo fieldName="Company Name" fieldValue={"Super Company"} />
          <CustomerCardInfo fieldName="Phone Number" fieldValue={"0183xxxxxxxx"} />
          <CustomerCardInfo fieldName="Email Address" fieldValue={"jakie@ma.me"} />
          <CustomerCardInfo fieldName="Address" fieldValue={"On 9 June the 101st finished consolidating, with the 502nd PIR guarding the right flank along the upper Douve River, the 506th PIR deployed across the Carentan highway"} />
          <CustomerCardInfo fieldName={<b>Referral By</b>} fieldValue={`Customer ( ${data.id} )`} />
        </div>
        <div className='mt-4'>
          <Button className='w-100 py-2' style={{ ...styles.border, display: 'flex', alignItems: 'center', justifyContent: 'center' }} variant="white">
            <Image src={EditIcon} className='mx-2' /> Edit
          </Button>
        </div>
      </Card.Body>
    </Card>
  );
}
const styles = {
  border: {
    border: '1px solid #C8C8C8',
    borderRadius: '5px'
  }
};
export default CharacterCard;