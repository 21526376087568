/* eslint-disable react/prop-types */
import React, { useMemo, useState, useEffect, useCallback } from 'react'
import { Row, Col, Form, Card, Button, FormGroup, Image } from 'react-bootstrap'
import { useForm, useFieldArray } from 'react-hook-form'
// import {
//   useSelector,
//   useDispatch,
// } from 'react-redux'
// import cleanDeep from 'clean-deep'
// import { useHistory } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import ReactstrapInput from '../../../components/form/input-fields/ReactstrapInput'
//
import EditIcon from '../../../assets/icons/common/edit.svg'
import PlusBlackIcon from '../../../assets/icons/common/plus-black.svg'
import UserIcon from '../../../assets/icons/sidebar/users-1.svg'
import AvatarIcon from '../../../assets/icons/admin/avatar.svg'

import { CLASSES } from '../../../assets/styles/classes'
import { useNavigate } from 'react-router-dom'

export default function AdminDetails({
  customerId,
  bloodGroupOptions,
  customerTypeOptions,
}) {
  const navigate = useNavigate();

  return (
    <div className='p-4'>
      <div className={CLASSES.content_between + 'mb-4'}>
        <h4 className={CLASSES.items_center}>
          {' '}
          <Image src={UserIcon} className='me-3' /> Admin Details
        </h4>
      </div>
      <Row>
        <Col
          md={6}
          lg={4}
          className={CLASSES.items_center + 'flex-column px-5 mb-5'}
        >
          <Image src={AvatarIcon} />
          <button onClick={() => navigate('/admin/edit/1')} className={CLASSES.content_center +'w-100 mt-5 border-radius-5 border-gray bg-white'}>
            <div className={CLASSES.content_between + 'my-2'}>
              <h5 className={CLASSES.items_center + 'mb-0'}>
                {' '}
                <Image src={EditIcon} className='me-3' /> Edit
              </h5>
            </div>
          </button>
        </Col>
        <Col md={6} lg={4} className='bg-gray d-flex flex-column border-radius-10 overflow-hidden p-0 mb-5'>
          <div className="bg-white h-100 ms-1 ps-3 border-radius-5">
            <div className='my-3 mt-1'>Admin Name : Mr. Hanny</div>
            <div className='my-3'>Admin ID : 89658668</div>
            <div className='my-3'>Phone Number : (880)585-965235</div>
            <div className='my-3'>Email Address : email@gmail.com</div>
            <div className='my-3'>User name : hanny245</div>
            <div className='my-3'>Password: **********</div>
          </div>
        </Col>
      </Row>
    </div>
  )
}
