/* eslint-disable react/prop-types */
import React, { useMemo, useState, useEffect, useCallback } from 'react'
import { Row, Col, Form, Card, Button, FormGroup, Image } from 'react-bootstrap'
import { useForm, useFieldArray } from 'react-hook-form'
// import {
//   useSelector,
//   useDispatch,
// } from 'react-redux'
// import cleanDeep from 'clean-deep'
// import { useHistory } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import ReactstrapInput from '../../../components/form/input-fields/ReactstrapInput'
//
import PlusIcon from '../../../assets/icons/common/plus-white.svg'
import PlusBlackIcon from '../../../assets/icons/common/plus-black.svg'
import UserIcon from '../../../assets/icons/sidebar/users-1.svg'

import { CLASSES } from '../../../assets/styles/classes'

export default function CreateCustomer({
  customerId,
  bloodGroupOptions,
  customerTypeOptions,
}) {
  // const dispatch = useDispatch()
  // const history = useHistory()
  const [csPhoto, setCSPhoto] = useState([])
  const [documents, setDocuments] = useState([])

  const {
    formState: { errors, isValid },
    watch,
    reset,
    control,
    setValue,
    handleSubmit,
  } = useForm({
    mode: 'all',
    defaultValues: {
      type_id: null,
      numbers: [{ number: '' }],
    },
    // resolver: yupResolver(customerSchema),
  })

  const { remove, fields, append } = useFieldArray({
    name: 'numbers',
    control,
  })

  const onSubmit = async (values) => {
    return false
  }

  return (
    <div className='p-4'>
      <div className={CLASSES.content_between + 'mb-4'}>
        <h4 className={CLASSES.items_center}>
          {' '}
          <Image src={UserIcon} className='me-3' /> Customers Information
        </h4>
        <Button className={CLASSES.items_center + 'bg-blue'}>
          <Image src={PlusIcon} className={'me-2'} /> Add Customer and Ticket
        </Button>
      </div>
      <Form className='d-flex flex-column justify-content-between'>
        <div className='bg-white card black-shadow-15 p-4 px-5'>
          <Row className='mb-3 text-bold'>
            <Col>Personal info.</Col>
          </Row>
          <Row className=''>
            <ReactstrapInput
              {...{
                label: 'First Name',
                name: 'name',
                type: 'text',
                placeholder: 'Enter customer first name',
                errorMessage: errors?.name?.message,
                control,
              }}
            />
            <ReactstrapInput
              {...{
                label: 'Last Name',
                name: 'contact',
                type: 'text',
                placeholder: 'Enter customer last name',
                errorMessage: errors?.contact?.message,
                control,
              }}
            />
            <ReactstrapInput
              {...{
                label: 'Business/ Company name (optional)',
                name: 'mobile',
                type: 'text',
                placeholder: 'Enter company name if have',
                errorMessage: errors?.mobile?.message,
                control,
              }}
            />

            <ReactstrapInput
              {...{
                label: 'Email id',
                name: 'email',
                type: 'email',
                placeholder: 'Enter customer Email id',
                errorMessage: errors?.email?.message,
                control,
              }}
            />
            {fields.map((item, i) => (
              <ReactstrapInput
                {...{
                  label: 'Phone Number ' + (i + 1),
                  name: `numbers.${i}.number`,
                  type: 'number',
                  placeholder: 'Enter customer Phone number ' + (i + 1),
                  errorMessage: errors.numbers?.[i]?.number?.message,
                  control,
                }}
              />
            ))}
            <Col lg='4' md='3' className={CLASSES.items_center}>
              <button
                type='button'
                onClick={() => append(1)}
                className={
                  CLASSES.items_center +
                  'bg-white text-gray border w-50 p-2 py-1 mb-3 mt-4'
                }
              >
                <Image src={PlusBlackIcon} className={'me-2'} /> Add more number
              </button>
            </Col>
          </Row>
          <Row className='my-4 text-bold'>
            <Col>Address</Col>
          </Row>
          <Row>
            <ReactstrapInput
              {...{
                label: 'Address Line 1',
                name: 'address_one',
                type: 'text',
                placeholder: 'Write address 1',
                errorMessage: errors?.address_one?.message,
                control,
              }}
            />
            <ReactstrapInput
              {...{
                label: 'Address Line 2',
                name: 'address_two',
                type: 'text',
                placeholder: 'Write address 2',
                errorMessage: errors?.address_one?.message,
                control,
              }}
            />
            <Col lg='4' md='3' className={CLASSES.items_center}>
              <button
                type='button'
                onClick={() => { }}
                className={
                  CLASSES.items_center +
                  'bg-white text-gray border w-50 p-2 py-1 mb-3 mt-4'
                }
              >
                <Image src={PlusBlackIcon} className={'me-2'} /> Add more address
              </button>
            </Col>
            {/* <DatePicker
            {...{
              label: 'Trade License Date Of Validity',
              name: 'trade_licence_expiry',
              placeholder: 'Choose validity date',
              errorMessage: errors?.trade_licence_expiry?.message,
              control,
            }}
          /> */}
            <ReactstrapInput
              {...{
                label: 'Street',
                name: 'licence',
                type: 'text',
                placeholder: 'Select here',
                errorMessage: errors?.licence?.message,
                control,
              }}
            />
            {/* <DatePicker
            {...{
              label: 'Bus. Segment License Date Of Validity',
              name: 'licence_expiry',
              placeholder: 'Choose validity date',
              errorMessage: errors?.licence_expiry?.message,
              control,
            }}
          /> */}
            <ReactstrapInput
              {...{
                label: 'City/Town',
                name: 'bin',
                type: 'text',
                placeholder: 'Select here',
                errorMessage: errors?.bin?.message,
                control,
              }}
            />
            <ReactstrapInput
              {...{
                label: 'State/province/Region',
                name: 'tin',
                type: 'text',
                placeholder: 'Select here',
                errorMessage: errors?.tin?.message,
                control,
              }}
            />
            <ReactstrapInput
              {...{
                label: 'Zip/postal Code',
                name: 'file_no',
                type: 'text',
                placeholder: 'Enter postal code',
                errorMessage: errors?.file_no?.message,
                control,
              }}
            />
            {/* <ReactSelect
            {...{
              label: 'Customer Type',
              name: 'type_id',
              placeholder: 'Choose type',
              errorMessage: errors?.type_id?.message,
              options: customerTypeOptions,
              control,
            }}
          /> */}
          </Row>
          <Row className='my-4 text-bold'>
            <Col>Referral information</Col>
          </Row>
          <Row>
            <ReactstrapInput
              {...{
                type: 'number',
                disabled: watch('cash'),
                name: 'credit_limit',
                label: 'Referred By',
                placeholder: 'Select here',
                errorMessage: errors?.credit_limit?.message,
                control,
              }}
            />
          </Row>
          <Row>
            <ReactstrapInput
              {...{
                type: 'number',
                disabled: watch('cash'),
                name: 'credit_limit',
                label: 'Phone/Email id',
                placeholder: 'Write here',
                errorMessage: errors?.credit_limit?.message,
                control,
              }}
            />
          </Row>
        </div>
        <Row className='mt-5'>
          <Col className='d-flex mt-1 mx-5'>
            <FormGroup className='w-100'>
              <Button
                className='mr-0 w-100 bg-blue border-0'
                color='primary'
                onClick={handleSubmit(onSubmit)}
              >
                Submit
              </Button>
            </FormGroup>
          </Col>
        </Row>
      </Form>
    </div>
  )
}
