import React from 'react'
import { Col } from 'react-bootstrap';

function CardColumn({ size_mid, column_name, element }) {

    return (
        <Col xs={12} md={size_mid} className='d-flex align-items-center flex-column mb-2'>
            <b style={{ fontSize: '14px' }}>{column_name}</b>
            <p className='mt-3 mb-0' style={{ fontSize: '13px' }}>{element}</p>
        </Col>
    );

}

export default CardColumn