import { useMediaQuery } from 'react-responsive'
import * as React from "react";
import { useReactToPrint } from 'react-to-print';

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal)



export const useScreenSizes = () => {
    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1224px)' });
    const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' });
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
    const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })

    return { isDesktopOrLaptop, isBigScreen, isTabletOrMobile, isPortrait, isRetina };
  };

  export const preConfirmAlert = async ({
    title = 'Submit updated data?',
    text = "You won't be able to revert this!",
    confirmButtonText = 'Save and Submit',
    denyButtonText = 'Submit',
    confirmButton = 'btn btn-primary',
    denyButton = 'btn btn-primary ml-1',
  }) => {
    const result = await MySwal.fire({
      title,
      text,
      icon: 'warning',
      showDenyButton: true,
      confirmButtonText,
      denyButtonText,
      customClass: {
        confirmButton,
        denyButton,
      },
      buttonsStyling: false,
      showLoaderOnConfirm: true,
      allowOutsideClick: true,
    })
    return result
  }

