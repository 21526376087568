import Invoice from "../pages/invoice/pages/Invoice";


const InvoiceRoutes = [
  {
    path: '/invoice',
    component: Invoice,
  },
  
];

export default InvoiceRoutes
