
import React from 'react'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { COLORS } from '../../../assets/styles/classes';

const CustomerTabs = ({onTabChange}) => {

    const [activeKey, setActiveKey] = React.useState('Ticket');
    const handleSelect = (eventKey) => {
        setActiveKey(eventKey);
        onTabChange(eventKey);
    };

    const Title = ({ name, showSeperator }) => {
        return (
            <div style={{ width: '80px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <span
                    style={activeKey == name ? styles.navLinkActive : null}
                > {name} </span>
                {showSeperator ? <span> | </span> : null}
            </div>
        )
    }

    return (
        <Tabs
            defaultActiveKey="Ticket"
            variant={false}
            onSelect={handleSelect}
            style={{ border: 'none', fontSize: '14px' }}
        >
            <Tab eventKey="Ticket" title={<Title name="Ticket" showSeperator={true} />} />
            <Tab eventKey="Invoice" title={<Title name="Invoice" showSeperator={true} />} />
            <Tab eventKey="Communicate" title={<Title name="Communicate" showSeperator={false} />} />
        </Tabs>
    );
}


const styles = {

    navLinkActive: {
        color: COLORS.blue,
        borderBottom: `2px solid ${COLORS.blue}`
    }
};


export default CustomerTabs;