import 'bootstrap/dist/css/bootstrap.min.css'
import '../src/app/assets/styles/styles.scss'
import RoutersList from './app/navigation'

// Bootstrap 5 has a wide range of responsive margin and padding utility classes.
// They work for all breakpoints: xs (<=576px), sm (>=576px), md (>=768px), lg (>=992px), xl (>=1200px) or xxl (>=1400px)):

function App() {
  return (
    <>
      <RoutersList />
    </>
  )
}

export default App
