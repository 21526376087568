import { Modal, ModalHeader, ModalBody } from 'react-bootstrap'
//

const ModalX = ({
  title = '',
  children = null,
  icon = undefined,
  responsive = false,
  className = '',
  isOpen = false,
  toggle = () => {},
  showModalHeader = true,
  modalHeaderClass = '',
  modalBodyClass = 'p-0',
  size = 'xl',
}) => {
  return (
    <Modal
      show={isOpen}
      size={size}
      onHide={toggle}
      className={`modal-dialog-centered ${className}`}
    >
      {showModalHeader && (
        <ModalHeader
          style={{ backgroundColor: 'white' }}
          className={`${modalHeaderClass}`}
        >
          {title && <h5 class='modal-title'>{title}</h5>}
          <button
            type='button'
            onClick={toggle}
            class='btn-close'
            data-bs-dismiss='modal'
            aria-label='Close'
          ></button>
        </ModalHeader>
      )}
      <ModalBody className={`${modalBodyClass}`}>{children}</ModalBody>
    </Modal>
  )
}

export default ModalX
