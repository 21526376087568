import Appointments from '../pages/appointment/pages/Appointments';
import CreateAppointment from '../pages/appointment/pages/CreateAppointment';


const AppointmentRoutes = [
  {
    path: '/appointments',
    component: Appointments,
  },
  {
    path: '/appointment/create',
    component: CreateAppointment,
  },
  {
    path: '/appointment/edit/:id',
    component: CreateAppointment,
  },
];

export default AppointmentRoutes
