import React from 'react'
import { CLASSES } from '../../../assets/styles/classes'
import { Button, Image } from 'react-bootstrap'
import BarChart from '../../../components/extras/BarChart'
import DropDownIcon from '../../../assets/icons/common/down-arrow-solid.svg'
import DropDownX from '../../../components/extras/DropDownX'

export default function BarGraphCard({ style, icon, title, value }) {
  return (
    <div
      className={'border-radius-10 black-shadow-15 overflow-hidden p-3'}
      style={style}
    >
      <div className={CLASSES.content_between}>
        <h6 className='text-gray'>Customer par Year</h6>
        {/* <div className={CLASSES.items_center+'border p-1 px-2'}>
          2023 <Image src={DropDownIcon} className={'ms-2'} />
        </div> */}
        <DropDownX
          items={['2023', '2024', '2025', '2026']}
          isBoldIcon
        />
      </div>

      <div className={'bg-white'}>
          <BarChart />
      </div>
    </div>
  )
}
